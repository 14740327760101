import {
  Button,
  CircularProgress,
  Container,
  Grid,
  Skeleton,
  Snackbar,
  Tab,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import StarHalfIcon from "@mui/icons-material/StarHalf";
import TabList from "@mui/lab/TabList";
// import { CustomerContext } from "../../context/CustomerContext";
import { Link, useNavigate } from "react-router-dom";
import { ShopContext } from "../../context/ShopContext";
import AddShoppingCartRoundedIcon from "@mui/icons-material/AddShoppingCartRounded";
import { WhatsappIcon } from "react-share";
import addCart from '../../assets/Svg/addToCart.svg'
import MuiAlert from '@mui/material/Alert';
import { set } from "lodash";
const ProductsArea = ({ products, query, isLoading, addToCart, shopDetails }) => {
  const navigate = useNavigate();
  // scroll auto
  const handleBuy = (prod) => {
    addToCart(prod);
    navigate("/cart");
  };
  const [isCopied, setIsCopied] = useState(false);
  const handleCloseSnackbar = () => {
    setIsCopied(false);
  };

  return (
    <>
      <Grid container spacing={2} mb={2}>
        {products.length > 0
          ? products?.map((items, idx) => (
            <Grid item xs={6} sm={4} md={3} lg={2} key={idx}>
              <Box
                sx={{
                  background: "#fff",
                  display: "flex",
                  width: "100%",
                  height: "100%",
                  zIndex: "1",
                  flexDirection: "column",
                  "&:hover": {
                    boxShadow: "0px 0px 9px 4px rgba(254,155,14,0.65)",

                  },
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {items ? (
                    <Box
                      sx={{
                        width: "100%",
                        position: "relative",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        p: 2,
                        ":hover": {
                          ".cartBlur": {
                            filter: "blur(10px)",
                            WebkitFilter: "blur(10px)",
                            transition: "filter 0.3s",
                          },
                        },
                      }}
                    >

                      <Link
                        to={`product/${items._id}`}
                        style={{
                          color: "#272727",
                          textDecoration: "none",
                        }}
                      >
                        <Box
                          // className="cartBlur"
                          sx={{
                            // zIndex: "2",
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            src={items?.thumbnail_image}
                            alt="item"
                            style={{
                              zIndex: "1",
                              width: "100%",
                              height: { sm: "200px", xs: "180px" },
                              maxHeight:"200px",
                              objectFit: "contain",
                              background:
                                "#FFFFFF 0% 0% no-repeat padding-box",
                            }}
                          />
                        </Box>
                      </Link>
                    </Box>
                  ) : (
                    <Skeleton
                      variant="rectangular"
                      sx={{ width: "100%", height: "280px" }}
                    />
                  )}
                </Box>

                {items ? (
                  <Box
                    sx={{
                      py: { sm: "10px", xs: "3px" },
                      px: { sm: "20px", xs: "5px" },
                    }}
                  >
                    <Box
                    // sx={{
                    //   ":hover": {
                    //     textDecoration: "underline",
                    //   },
                    // }}
                    >
                      <Typography
                        sx={{
                          textAlign: "left",
                          textOverflow: "ellipsis",
                          maxWidth: "100%",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          fontWeight: "500",
                          fontSize: { sm: "14px", xs: "14px" },
                        }}
                      >
                        {items.product_name}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "start",
                      }}
                    >
                      <Box sx={{ display: "flex", flexDirection: "column", }}>
                        {items.discount_price != 0 ? (
                          <Typography
                            sx={{
                              fontSize: { sm: "17px", xs: "18px" },
                              fontWeight: "500",
                              color: "#FE9B0E",
                              minWidth: "90px"
                            }}
                          >
                            Rs.{items.discount_price}
                          </Typography>
                        ) : (
                          <Typography
                            sx={{
                              fontSize: { sm: "17px", xs: "18px" },
                              fontWeight: "500",
                              color: "#FE9B0E",
                              minWidth: "90px"
                            }}
                          >
                            Rs.{items.price}
                          </Typography>
                        )
                        }

                        {items.discount_price != 0 ? (
                          <Box
                            sx={{
                              mt: "6px",
                            }}
                          >
                            <Typography
                              sx={{
                                position: "relative",
                                fontSize: { sm: "14px", xs: "12px" },
                                fontWeight: "400",
                                color: "gray",
                                ml: "4px",
                                textDecoration: "line-through",
                              }}
                            >
                              Rs. {items.price}
                            </Typography>
                          </Box>
                        ) : (
                          <p></p>
                        )}
                      </Box>

                      {
                        shopDetails?.on_Whatsapp && shopDetails?.phone_number && (
                          <Tooltip title="Order via Whatsapp" followCursor>
                        <Typography
                        sx={{
                          mr:"4px",
                          "&:hover": {
                            cursor:"pointer"
                          },
                        }}
                        >
                          <WhatsappIcon
                            size={25} round={true}
                            onClick={() =>
                              window.open(
                                `${process.env.REACT_APP_FRONTEND_URL}/whatsapp-checkout?pid=${items._id}&bid=${items.user_id}`,
                                "_blank"
                              )
                            }
                          />
                        </Typography>
                      </Tooltip>
                        )
                      }
                      <Tooltip title="Add to Cart" followCursor>

                        <Typography
                        sx={{
                          "&:hover": {
                            cursor:"pointer"
                          },
                        }}
                        >
                          <img
                            height="25px"
                            src={addCart}
                            onClick={() =>{
                              items?.is_varient ? navigate(`product/${items._id}`) :
                               addToCart(items)

                            setIsCopied(true)
                            }}
                          />
                        </Typography>
                      </Tooltip>
                      {/* <Snackbar
        open={isCopied}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <MuiAlert elevation={6} variant="filled" onClose={handleCloseSnackbar} severity="success">
        Item added successfully
        </MuiAlert>
      </Snackbar> */}
                    </Box>
                  </Box>
                ) : (
                  <Box sx={{ py: 0.5, px: "10px" }}>
                    <Skeleton />
                    <Skeleton width="60%" />
                  </Box>
                )}
              </Box>
            </Grid>
          ))
          : !isLoading && (
            <Grid item xs={12} sm={6} md={3}>
              <Typography>No Products Found</Typography>
            </Grid>
          )
        }
      </Grid>

      {isLoading && (
        <Grid container spacing={2}>
          {Array.from(new Array(6))?.map((items, idx) => (
            <Grid item xs={6} sm={4} md={3} lg={2} key={idx}>
              <Box
                sx={{
                  background: "#EFF9FD",
                  display: "flex",
                  width: "100%",
                  height: "100%",
                  flexDirection: "column",
                  "&:hover": {
                    boxShadow: "2px 2px 4px #888888",
                    cursor: "pointer",
                  },
                }}
                onClick={() => !isLoading && navigate(`product/${items._id}`)}
              >
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {items ? (
                    <img
                      src={items?.images[0]?.image}
                      alt="item"
                      style={{
                        width: "210px",
                        height: "250px",
                        objectFit: "contain",
                      }}
                    />
                  ) : (
                    <Skeleton
                      variant="rectangular"
                      sx={{ width: "100%", height: "280px" }}
                    />
                  )}
                </Box>

                {items ? (
                  <>
                    <Typography
                      sx={{
                        textAlign: "left",
                        fontSize: "12px",
                        p: "5px",
                      }}
                    >
                      {items.product_name}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        p: "5px",
                        alignItems: "center",
                      }}
                    >
                      <Typography sx={{ fontSize: "16px", fontWeight: "bold" }}>
                        Rs. {items.price}
                      </Typography>
                      {/* <Box sx={{ display: "flex" }}>
                        <Typography
                          sx={{ fontSize: "16px", fontWeight: "bold" }}
                        >
                          4.2
                        </Typography>
                        <StarHalfIcon sx={{ color: "gold" }} />
                      </Box> */}
                    </Box>
                  </>
                ) : (
                  <Box sx={{ py: 0.5, px: "10px" }}>
                    <Skeleton />
                    <Skeleton width="60%" />
                  </Box>
                )}
              </Box>
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
};

const ItemsSection = ({
  value,
  page,
  totalPages,
  handleTabChange,
  products,
  navigate,
  query,
  loadMore,
  isLoading,
  fetchProducts,
  setPage
}) => {
  const { fetchProductCategories, store, addToCart, shopDetails } = useContext(ShopContext);

  // const handleScroll = () => {
  //   const scrollHeight = document.documentElement.scrollHeight;
  //   const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  //   const clientHeight = document.documentElement.clientHeight;
  //   const nearBottom = scrollHeight - (scrollTop + clientHeight) < 100;
  //   if (nearBottom && !isLoading && page < totalPages) {
  //     // setPage((prevPage) => prevPage + 1);
  //     loadMore();
  //   }
  // };
  // useEffect(() => {
  //   window.addEventListener('scroll', handleScroll);
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, [handleScroll]);
  // useEffect(() => {
  //   fetchProducts(page, false);
  // }, [page]);
  useEffect(() => {
    fetchProductCategories(query);
  }, []);

  return (
    <Container maxWidth="lg">
      <TabContext value={value}>
        <Box
          sx={{
            borderBottom: "1px solid #00000029",
            borderColor: "divider",
            pb: "10px",
          }}
        >
          <TabList
            onChange={handleTabChange}
            aria-label="lab API tabs example"
            // indicatorColor=""
            sx={{ px: "0px", height: "30px", minHeight: "30px" }}
          >
            <Tab
              label="All"
              value="all"
              sx={{
                // borderRadius: "20px",
                height: "30px",
                minHeight: "30px",
                "&.Mui-selected": {
                  // backgroundColor: "black",
                  // color: "black",
                },
              }}
            />
            {store?.product_category?.map((category) => (
              <Tab
                label={category}
                value={category}
                sx={{
                  color: "#707070",
                  borderRadius: "20px",
                  height: "30px",
                  minHeight: "30px",
                  "&.Mui-selected": {
                    // backgroundColor: "black",
                    // color: "#fff",
                  },
                }}
              />
            ))}
          </TabList>
        </Box>

        <TabPanel value="all" sx={{ p: "0", py: "25px" }}>
          <ProductsArea
            products={products}
            query={query}
            isLoading={isLoading}
            addToCart={addToCart}
            shopDetails={shopDetails}
          />
        </TabPanel>

        {store?.product_category?.map((category) => (
          <TabPanel value={category} sx={{ p: "0", py: "25px" }}>
            <ProductsArea
              products={products}
              query={query}
              isLoading={isLoading}
              addToCart={addToCart}
            />
          </TabPanel>
        ))}
      </TabContext>

      {page < totalPages && (
        <Button
          sx={{
            color: "#FE9B0E",
            width: "300px",
            border: "1px solid #FE9B0E", // Set border color and width
            "&:hover": {
              transform: "scale(1.07)",
              transition: "transform 0.2s ease",
            },
            mx: "auto",
            display: "block",
            mb: "6px",
          }}
          onClick={loadMore}
        >
          LOAD MORE
        </Button>
      )}
    </Container>
  );
};

export default ItemsSection;
