import { Box, Button, Container, Divider, Typography } from "@mui/material";
import React from "react";
import FacebookGraySvg from "../../assets/Svg/FacebookGraySvg";
import InstaGraySvg from "../../assets/Svg/InstaGraySvg";
import TwitterGraySvg from "../../assets/Svg/TwitterGraySvg";
import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";
import { useNavigate } from "react-router-dom";

const pages = [
  { title: "About", path: "about-us" },
  { title: "Terms of Service", path: "/terms-of-service" },
  { title: "Privacy Policy", path: "/privacy" },
];

const Footer = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        background: "#F9B327",
      }}
    >
      <Container
        sx={{
          py: "20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: { xs: "column", sm: "row" },
        }}
        maxWidth="xl"
      >
        <img
          src={require("../../assets/Img/LogoW.png")}
          alt="logo"
          style={{ width: "150px" }}
          onClick={() => navigate("/")}
        />

        <Box
          sx={{
            // flexGrow: 1,
            display: "flex",
            flexDirection: { xs: "row", sm: "row" },
            gap: 3,
          }}
        >
          {pages.map((page, idx) => (
            <Button
              key={idx}
              sx={{
                my: 2,
                color: "white",
                display: "block",
                mb: "0px",
                textTransform: "none",
              }}
              onClick={() => navigate(page.path)}
            >
              {page.title}
            </Button>
          ))}
        </Box>
      </Container>
      <Divider />
      <Container
        sx={{
          py: "20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: { xs: "column", sm: "row" },
          gap: { xs: 2, sm: "0" },
        }}
        maxWidth="xl"
      >
        <Box sx={{ display: "flex", gap: 1 }}>
          <FacebookGraySvg />
          <InstaGraySvg />
          <TwitterGraySvg />
        </Box>

        <Box sx={{ display: "flex" }}>
          <Typography
            component={"p"}
            sx={{
              color: "#fff",
              display: "flex",
              fontSize: "13px",
            }}
          >
            Powered by&nbsp;
            <span
              component={"p"}
              style={{ fontWeight: "bold", fontSize: "13px" }}
            >
              METAMORPH DIGITAL (PRIVATE) LIMITED
            </span>
          </Typography>
        </Box>

        {/* <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <Typography
            component={"p"}
            sx={{
              color: "#fff",
              display: "flex",
              fontSize: "13px",
              alignItems: "center",
            }}
          >
            <HeadsetMicIcon />
            &nbsp;+92 310 1234567
          </Typography>
        </Box> */}
      </Container>
    </Box>
  );
};

export default Footer;
