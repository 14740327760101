import { Box } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import GallerySection from "./GallerySection";
import ItemsSection from "./ItemsSection";
import NewArrivalSection from "./NewArrivalSection";
import FeaturedProduct from "./FeaturedProduct";
import {
  fetchAllProductsByShopName,
  searchProducts,
} from "../../api/ProductApis";
import { SearchContext } from "../../context/SearchContext";

const MainComp = () => {
  const navigate = useNavigate();
  const {
    handleSearch,
    searchQuery,
    setSearchQuery,
    sortProductsByPrice,
    removeSearch,
    isSearched,
    value,
    page,
    totalPages,
    handleTabChange,
    products,
    query,
    loadMore,
    loading,
    totalCount,
    fetchProducts,
    setPage,
    handleSearchSuggestion
  } = useContext(SearchContext);

  return (
    <Box sx={{ minHeight: "100vh" }}>
      {/* gallery search section */}
      <GallerySection
        handleSearch={handleSearch}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        sortProductsByPrice={sortProductsByPrice}
        removeSearch={removeSearch}
        isSearched={isSearched}
        totalCount={totalCount}
      />

      {/* gallary item section */}
      <ItemsSection
        value={value}
        page={page}
        totalPages={totalPages}
        handleTabChange={handleTabChange}
        products={products}
        navigate={navigate}
        query={query}
        loadMore={loadMore}
        isLoading={loading}
        setPage={setPage}
        fetchProducts={fetchProducts}
      />

      {/* new arrivals */}
      <NewArrivalSection />

      {/* featured product */}
      <FeaturedProduct />
    </Box>
  );
};

export default MainComp;
