import { LiveTv } from "@mui/icons-material";
import {
  Box,
  Button,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState, } from "react";
import { useNavigate } from "react-router-dom";
import MyStreamsModal from "../../Streaming/MyStreamsModal";
import { StreamingContext } from "../../../context/StreamingContext";
import { getUserRecordedStreams } from "../../../api/StreamApis";

const styles = {
  buttonLabel: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transition: "all 0.3s",
  },
};

const MyStreams = () => {
  const navigate = useNavigate();

  const [streams, setStreams] = useState([]);
  const {recordedStreamsModal,handleStreamModal } = useContext(StreamingContext);
  const fetchUsersStreams = async (page) => {
    const response = await getUserRecordedStreams(page);
    if (response?.status === 200) {
      setStreams(response?.data?.Recorded);
    }
  };
  useEffect(() => {
    fetchUsersStreams(1);
  }, []);
  return (
    <Box>
      <Typography
        sx={{
          fontWeight: "500",
          fontSize: "16px",
          mt: "20px",
          color: "#322F2F",
          mb: "5px",
        }}
      >
        My Streams
      </Typography>
      <Box
        sx={{
          padding: "25px",
          background: "#EFF9FD",
          borderRadius: "15px",
          mb: "15px",
        }}
      >
       {
        streams.length>0 ?
        <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: 2,
          py: "30px",
          width: "70%",
          mx: "auto",
        }}
      >
        <Button
          onClick={handleStreamModal}
          sx={{
            background: "#FC6B00",
            color: "#fff",
            display: "flex",
            alignItems: "center",
            gap: 1,
            px: { xs: "", md: "20px" },
            borderRadius: "20px",
            height: "30px",
            width: "120px",
            my: "auto",
            ":hover": {
              background: "#FC6B00",
              "& > .button-label": {
                transform: "translateX(-100%)",
              },
              "& > .button-icon": {
                opacity: 1,
              },
            },
            position: "relative",
            overflow: "hidden",
            transition: "all 0.3s",
          }}
        >
          {/* Go Live */}
          <span className="button-label" style={styles.buttonLabel}>
            My Streams
          </span>
          <LiveTv
            className="button-icon"
            sx={{
              fontSize: "18px",
              mt: "-1px",
              display: { xs: "none", md: "block" },
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              opacity: 0,
              transition: "all 0.3s",
            }}
          />
        </Button>
      </Box>:
      <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: 2,
        py: "30px",
        width: "70%",
        mx: "auto",
      }}
    >
      <Typography sx={{ color: "#707070FA", textAlign: "center" }}>
        You haven't created any streams yet, but you're one step closer.
        Simply click the 'Go Live' button below to start creating your first
        stream.
      </Typography>
      <Button
        onClick={() => navigate("/streaming")}
        sx={{
          background: "#FC6B00",
          color: "#fff",
          display: "flex",
          alignItems: "center",
          gap: 1,
          px: { xs: "", md: "20px" },
          borderRadius: "20px",
          height: "30px",
          width: "120px",
          my: "auto",
          ":hover": {
            background: "#FC6B00",
            "& > .button-label": {
              transform: "translateX(-100%)",
            },
            "& > .button-icon": {
              opacity: 1,
            },
          },
          position: "relative",
          overflow: "hidden",
          transition: "all 0.3s",
        }}
      >
        {/* Go Live */}
       
        {/* create a button  */}
        
       
        <span className="button-label" style={styles.buttonLabel}>
          Go Live
        </span>
        <LiveTv
          className="button-icon"
          sx={{
            fontSize: "18px",
            mt: "-1px",
            display: { xs: "none", md: "block" },
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            opacity: 0,
            transition: "all 0.3s",
          }}
        />
      </Button>
    </Box>
       }
        

        {/* <Grid container spacing={1}>
      {videos.map((video, idx) => (
        <Grid item key={idx} xs={12} sm={6} xsm={4} md={6} xmd={4} lg={3}>
          <Box
            sx={{
              width: "100%",
              height: "150px",
              background: "gray",
              borderRadius: "10px",
            }}
          >
            <video src={video?.url} width="100%" height="150" />
          </Box>
        </Grid>
      ))}
    </Grid> */}
      </Box>
      <MyStreamsModal
   handleStreamModal={handleStreamModal}
   recordedStreamsModal={recordedStreamsModal}
   />
    </Box>
  );
};

export default MyStreams;
