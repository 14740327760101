import { Box, Button, Dialog, DialogContent, Typography } from '@mui/material'
import React, { useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';

const ChartModal = ({sizeChartDialog,handleClose,chart}) => {
    const [isHovered, setIsHovered] = useState(false);
    const handleMouseEnter = () => {
      setIsHovered(true);
    };
    const handleMouseLeave = () => {
      setIsHovered(false);
    };
  return (
    <Box>
        <Dialog
        open={sizeChartDialog}
        onClose={handleClose}
        PaperProps={{
          style: { borderRadius: "15px" },
        }}
        scroll={"body"}
        fullWidth={true}
        maxWidth={"sm"}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: {
                xs: "290px",
                xsPlus: "330px",
                sm: "500px",
                md: "400px",
                lg: "500px",
                br500: "430px",
              }, // Set your width here
            },
          },
        }}
      >
        <DialogContent
          sx={{
            background: "#fff",
            color: "#fff",
            position: "relative",
            padding: "25px",
            // width: "800px",
            overflow: "visible",
            p: { xs: "10px", md: "10px", lg: "10px" },
          }}
        >
          <Box sx={{

            pb: "6px",
            color: "black",
            display: 'flex',
            justifyContent: "center",
          }}>
            <Typography
              variant="h6"
              sx={{
                color: "black",
                fontWeight: "500",
              }}
            >
              Size Chart
            </Typography>

          </Box>
            <Box sx={{
                display: 'flex',
                justifyContent: "center",
            }}>
                <img src={chart} alt="size chart" style={{ width: "100%", height: "510px",objectFit: "contain", objectPosition: "center"
                 }} />
            </Box>
          
           
             
              
            
        
          <Button
            style={{
              position: 'absolute',
              color: isHovered ? 'white' : "black",
              top: 6,
              right: 8,
              padding: 1,
              minWidth: 'fit-content',
              borderRadius: '50%',
              transition: 'background-color 0.3s ease-in-out',
              backgroundColor: isHovered ? 'rgba(0, 0, 0, 0.5)' : 'transparent',
            }}
            onClick={handleClose}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <CloseIcon />
          </Button>
        </DialogContent>
      </Dialog>
    </Box>
  )
}

export default ChartModal