import React from "react";

const FacebookGraySvg = () => {
  return (
    <svg
      id="Group_513"
      data-name="Group 513"
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="33"
      viewBox="0 0 33 33"
    >
      <circle
        id="Ellipse_51"
        data-name="Ellipse 51"
        cx="16.5"
        cy="16.5"
        r="16.5"
        fill="#fff"
      />
      <path
        id="Icon_awesome-facebook-f"
        data-name="Icon awesome-facebook-f"
        d="M11.747,11.394l.563-3.666H8.792V5.349a1.833,1.833,0,0,1,2.067-1.98h1.6V.248A19.5,19.5,0,0,0,9.619,0c-2.9,0-4.79,1.756-4.79,4.934V7.728H1.609v3.666h3.22v8.862H8.792V11.394Z"
        transform="translate(9.254 6.429)"
        fill="#322f2f"
      />
    </svg>
  );
};

export default FacebookGraySvg;
