import { Box, Button, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import PasswordResetSvg from "../../assets/Svg/PasswordResetSvg";
import ResetDoneSvg from "../../assets/Svg/ResetDoneSvg";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { sendBusinessUserPassword } from "../../api/AuthApis";

const ResetToken = () => {
  const { token } = useParams();

  const navigate = useNavigate();
  const [reset, setReset] = useState({
    password: "",
    cPassword: "",
    loading: false,
    isDone: false,
  });

  const handlePasswordReset = async () => {
    if (!reset.password || !reset.cPassword) {
      toast.error("Error: Invalid password entered. ");
      return;
    }

    if (reset.password !== reset.cPassword) {
      toast.error("Error: Passwords do not match. ");
      return;
    }

    if (
      !reset.password.match(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/
      )
    ) {
      toast.error(
        "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character. "
      );
      return;
    }

    setReset({ ...reset, loading: true });

    const resp = await sendBusinessUserPassword(
      {
        password: reset.password,
      },
      token
    );

    if (resp?.status === 200) {
      setReset({ ...reset, isDone: true, loading: false });
    } else {
      setReset({ ...reset, loading: false });
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: 3,
      }}
    >
      <Box
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        {reset.isDone ? <ResetDoneSvg /> : <PasswordResetSvg />}
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{ fontWeight: "600", fontSize: "20px", color: "#252525" }}
        >
          {reset.isDone ? "PASSWORD RESET" : " SET NEW PASSOWRD"}
        </Typography>
        {reset.isDone && (
          <Typography
            sx={{
              fontSize: "12px",
              color: "#8E8E8E",
              textAlign: "center",
              width: "100%",
            }}
          >
            Your password has been successfully reset.
            <br /> Click below to log in.
          </Typography>
        )}
      </Box>
      {!reset.isDone && (
        <>
          <TextField
            size="small"
            type="password"
            label="New Password"
            value={reset.password}
            onChange={(e) => setReset({ ...reset, password: e.target.value })}
          />
          <TextField
            size="small"
            type="password"
            label="Confirm Password"
            value={reset.cPassword}
            onChange={(e) => setReset({ ...reset, cPassword: e.target.value })}
          />
        </>
      )}

      {reset.isDone ? (
        <Button
          variant="contained"
          color="secondary"
          sx={{ borderRadius: "20px" }}
          onClick={() => navigate("/auth/login")}
        >
          Login
        </Button>
      ) : (
        <Button
          variant="contained"
          color="secondary"
          sx={{ borderRadius: "20px" }}
          onClick={handlePasswordReset}
          disable={reset.loading}
        >
          Submit
        </Button>
      )}
    </Box>
  );
};

export default ResetToken;
