import React, { createContext, useEffect, useState } from "react";
import { getProductCategories } from "../api/ProductApis";
import { getShopDetailsForCustomerView } from "../api/StoreApis";
import { useLocation } from "react-router-dom";

export const ShopContext = createContext();

export const ShopProvider = ({ children }) => {
  const getSubdomainFromURL = () => {
    // const hostname = window.location.hostname;
    // const subdomain = hostname.split(".")[0];
    if (localStorage.getItem("businessUser")) {
      return false;
    }

    return true;
  };

  const { search } = useLocation();

  const [subdomain, setSubdomain] = useState(getSubdomainFromURL());
  const [store, setStore] = useState({});
  const [shopDetails, setShopDetails] = useState({});
  const [query, setQuery] = useState("");

  const [cartItems, setCartItems] = useState([]);
  const [checkoutItems, setCheckoutItems] = useState([]);

  const addToCart = (product, quantity = 1) => {
    let existingProductIndex ;
    console.log("product in add to cart",product);
    if(product?.is_varient && product?.option_id && !product?.group_id){
      existingProductIndex = cartItems.findIndex(
        (item) => item._id === product?._id && item?.option_id === product?.option_id
      );
    }
    else if(product?.is_varient && product?.option_id && product?.group_id){
      existingProductIndex = cartItems.findIndex(
        (item) => item._id === product?._id && item?.option_id === product?.option_id && item?.group_id === product?.group_id
      );
    }else{
    existingProductIndex = cartItems.findIndex(
      (item) => item._id === product._id 
    );
  }

    if (existingProductIndex !== -1) {
      const updatedCartItems = [...cartItems];
      updatedCartItems[existingProductIndex].quantity += quantity;
      setCartItems(updatedCartItems);
    } else {
      setCartItems((prev) => [...prev, { ...product, quantity }]);
    }
  };
  const updateQuantity = (productId, quantity) => {
    let existingProductIndex;
    if(productId?.is_varient && productId?.option_id && !productId?.group_id){
      existingProductIndex = cartItems.findIndex(
        (item) => item._id === productId._id && item?.option_id === productId?.option_id
      );
    }
    else if(productId?.is_varient && productId?.option_id && productId?.group_id){
      existingProductIndex = cartItems.findIndex(
        (item) => item._id === productId._id && item?.option_id === productId?.option_id && item?.group_id === productId?.group_id
      );
    }
    else{
      existingProductIndex = cartItems.findIndex(
        (item) => item._id === productId._id
      );
    }
  
    if (quantity === 0) {
      let copyArr = JSON.parse(JSON.stringify(cartItems));
      copyArr.splice(existingProductIndex, 1);
      setCartItems(copyArr);
    } else {
      if (existingProductIndex !== -1) {
        const updatedCartItems = [...cartItems];
        updatedCartItems[existingProductIndex].quantity = quantity;
        setCartItems(updatedCartItems);
      }
    }
  };

  // const updateQuantity = (productId, quantity) => {
  //   console.log("productId quantity",productId,quantity)
  //   let existingProductIndex;
  //   if(productId?.is_varient && productId?.option_id && !productId?.group_id){
  //     existingProductIndex = cartItems.findIndex(
  //       (item) => item._id === productId?._id && item?.option_id === productId?.option_id
  //     );
  //   }
  //   else if(productId?.is_varient && productId?.option_id && productId?.group_id){
  //     existingProductIndex = cartItems.findIndex(
  //       (item) => item._id === productId?._id && item?.option_id === productId?.option_id && item?.group_id === productId?.group_id
  //     );
  //   }
  //   else{
  //   existingProductIndex = cartItems.findIndex(
  //     (item) => item._id === productId
  //   );
  //   }
  //   console.log("existingProductIndex",existingProductIndex)
  //   if (quantity === 0) {
  //     let copyArr = JSON.parse(JSON.stringify(cartItems));
  //     copyArr.splice(existingProductIndex, 1);
  //     setCartItems(copyArr);
  //   } else {
  //     if (existingProductIndex !== -1) {
  //       const updatedCartItems = [...cartItems];
  //       updatedCartItems[existingProductIndex].quantity = quantity;
  //       setCartItems(updatedCartItems);
  //     }
  //   }
  // };

  const getCartItemsQuantity = () => {
    let qty = 0;
    cartItems.forEach((item) => (qty += item.quantity));
    return qty;
  };

  const getCheckoutItemsQuantity = () => {
    let qty = 0;
    checkoutItems.forEach((item) => (qty += item.quantity));
    return qty;
  };

  const removeItem = (row) => {
    let updatedItems;
    if(row?.is_varient && row?.option_id && !row?.group_id){
      updatedItems = cartItems.filter(
        (item) => item._id !== row._id || item?.option_id !== row?.option_id
      );
    }
    else if(row?.is_varient && row?.option_id && row?.group_id){
      updatedItems = cartItems.filter(
        (item) => item._id !== row._id || item?.option_id !== row?.option_id || item?.group_id !== row?.group_id
      );
    }
    else{
      updatedItems = cartItems.filter((item) => item._id !== row._id);
    }
    setCartItems(updatedItems);
  };

  const removeCheckoutItem = (row) => {
    let updatedItems;
    if(row?.is_varient && row?.option_id && !row?.group_id){
      updatedItems = checkoutItems.filter(
        (item) => item._id !== row._id || item?.option_id !== row?.option_id
      );
    }
    else if(row?.is_varient && row?.option_id && row?.group_id){
      updatedItems = checkoutItems.filter(
        (item) => item._id !== row._id || item?.option_id !== row?.option_id || item?.group_id !== row?.group_id
      );
    }
    else{
      updatedItems = checkoutItems.filter((item) => item._id !== row._id);
    }
    setCheckoutItems(updatedItems);
    let updatedCartItems;
     updatedCartItems = cartItems.map((item) => {
      if(row?.is_varient && row?.option_id && !row?.group_id){
        if (item._id === row._id && item?.option_id === row?.option_id) {
          item.checked = false;
        }
      }
      else if(row?.is_varient && row?.option_id && row?.group_id){
        if (item._id === row._id && item?.option_id === row?.option_id && item?.group_id === row?.group_id) {
          item.checked = false;
        }
      }
      else{
        if (item._id === row._id) {
          item.checked = false;
        }
      }
      return item;
    });

    setCartItems(updatedCartItems);
  };

  const selectAllItems = () => {
    const updatedItems = cartItems.map((item) => {
      item.checked = true;
      return item;
    });

    setCartItems(updatedItems);
    setCheckoutItems(updatedItems);
  };

  const unSelectAllItems = () => {
    const updatedItems = cartItems.map((item) => {
      item.checked = false;
      return item;
    });

    setCartItems(updatedItems);
    setCheckoutItems([]);
  };

  const selectItem = (row, value) => {
    const updatedItems = cartItems.map((item) => {
      if(row?.is_varient && row?.option_id && !row?.group_id){
        if (item._id === row._id && item?.option_id === row?.option_id) {
          item.checked = value;
        }
      }
      else if(row?.is_varient && row?.option_id && row?.group_id){
        if (item._id === row._id && item?.option_id === row?.option_id && item?.group_id === row?.group_id) {
          item.checked = value;
        }
      }
      else{
        if (item._id === row._id) {
          item.checked = value;
        }
      }
      return item;
    });

    setCartItems(updatedItems);

    if (value) {
      setCheckoutItems([...checkoutItems, row]);
    } else {
      let updatedItems;
      if(row?.is_varient && row?.option_id && !row?.group_id){
        updatedItems = checkoutItems.filter(
          (item) => item._id !== row._id || item?.option_id !== row?.option_id
        );
      }
      else if(row?.is_varient && row?.option_id && row?.group_id){
        updatedItems = checkoutItems.filter(
          (item) => item._id !== row._id || item?.option_id !== row?.option_id || item?.group_id !== row?.group_id
        );
      }
      else{
       updatedItems = checkoutItems.filter((item) => item._id !== row._id);
      }
      setCheckoutItems(updatedItems);
    }
  };

  const allItemsSelected = () => {
    if (cartItems.length === 0) {
      return false;
    }

    return cartItems.every((item) => item.checked);
  };

  const fetchProductCategories = async (query) => {
    const response = await getProductCategories(query);
    if (response?.status === 200) {
      setStore(response.data?.shop);
    }
  };

  const fetchShopDetails = async () => {
    const response = await getShopDetailsForCustomerView(query);
    if (response?.status === 200) {
      let data = response.data.shop_details;
      setShopDetails(data);
    }
  };

  const removeAll = () => {
    setCartItems([]);
    setCheckoutItems([]);
  };
  // remove item from cart
  const removeAnItem=(id)=>{
    const updatedItems=cartItems.filter(item=>item._id!==id);
    setCartItems(updatedItems);
  }

  useEffect(() => {
    if (new URLSearchParams(search).get("q")) {
      setQuery(new URLSearchParams(search).get("q"));
    } else {
      setQuery(window.location.hostname.split(".")[0]);
    }

    setSubdomain(getSubdomainFromURL());
  }, []);

  useEffect(() => {
    if(query){
      fetchShopDetails();
      fetchProductCategories(query);
    }
  }, [query]);

  return (
    <ShopContext.Provider
      value={{
        subdomain,
        setSubdomain,
        fetchProductCategories,
        store,
        shopDetails,
        removeAll,
        cartItems,
        removeCheckoutItem,
        addToCart,
        removeItem,
        updateQuantity,
        setCartItems,
        selectItem,
        getCartItemsQuantity,
        getCheckoutItemsQuantity,
        checkoutItems,
        setCheckoutItems,
        selectAllItems,
        unSelectAllItems,
        allItemsSelected,
        removeAnItem
       
      }}
    >
      {children}
    </ShopContext.Provider>
  );
};
