import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useContext } from "react";

import { AuthContext } from "../../context/AuthContext";
import EmptyView from "../../assets/Img/test.png";

const InvoicePDF = React.forwardRef((props, ref) => {
  const { userData } = useContext(AuthContext);

  const formatTime = (time) => {
    const date = new Date(time);
    const options = {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    };

    return date.toLocaleTimeString(undefined, options);
  };

  return (
    <Box ref={ref}>
      <Box
        sx={{
          p: "20px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: "2px solid #707070",
        }}
      >
        <Box sx={{ display: "flex", gap: 1.5, alignItems: "center" }}>
          {/* <Box
            sx={{
              border: "1px solid #707070",
              borderRadius: "5px",
              width: "50px",
              height: "50px",
            }}
          >
            {userData?.user?.store?.shop_logo && (
              <img
                src={userData?.user?.store?.shop_logo}
                alt="logo"
                style={{ width: "50px", height: "50px", objectFit: "contain" }}
              />
            )}
          </Box> */}

          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography sx={{ color: "black", fontWeight: "500" }}>
              {userData?.user?.store?.store_name}
            </Typography>
            <Typography sx={{ color: "black", fontSize: "10px" }}>
              {userData?.user?.store?.phone_number}
            </Typography>
            <Typography sx={{ color: "black", fontSize: "10px" }}>
              {userData?.user?.email}
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{ display: "flex", flexDirection: "column", alignItems: "end" }}
        >
          <Typography sx={{ color: "black", fontWeight: "500" }}>
            Order ID
          </Typography>
          <Typography sx={{ color: "black", fontSize: "10px" }}>
            {props?.invoice?.order_number}
          </Typography>
          {props?.invoice?.delivery?.track_number && (
            <>
              <Typography
                sx={{ color: "black", fontSize: "12px", fontWeight: "500" }}
              >
                Tracking Number
              </Typography>
              <Typography sx={{ color: "black", fontSize: "10px" }}>
                {props?.invoice?.delivery?.track_number}
              </Typography>
            </>
          )}
        </Box>
      </Box>

      <Box
        sx={{
          p: "20px",
          display: "flex",
          justifyContent: "space-between",
          borderBottom: "1px solid #707070",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography sx={{ color: "black", fontWeight: "500" }}>
            Bill To
          </Typography>
          <Typography sx={{ color: "black", fontSize: "10px" }}>
            {props?.invoice?.shipping_details?.full_name ||
              props?.invoice?.shipping_details[0]?.full_name}
          </Typography>
          <Typography sx={{ color: "black", fontSize: "10px" }}>
            {props?.invoice?.shipping_details?.phone_number ||
              props?.invoice?.shipping_details[0]?.phone_number}
          </Typography>
          <Typography sx={{ color: "black", fontSize: "10px" }}>
            {props?.invoice?.shipping_details?.address ||
              props?.invoice?.shipping_details[0]?.address}
          </Typography>
          <Typography sx={{ color: "black", fontSize: "10px" }}>
            {props?.invoice?.shipping_details?.city?.name ||
              props?.invoice?.shipping_details[0]?.city?.name}
          </Typography>
          <Typography sx={{ color: "black", fontSize: "10px" }}>
            {props?.invoice?.shipping_details?.postal_code ||
              props?.invoice?.shipping_details[0]?.postal_code}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "end",
          }}
        >
          <Typography sx={{ color: "black", fontWeight: "500" }}>
            Status
          </Typography>
          <Typography sx={{ color: "black", fontSize: "10px" }}>
            {props?.invoice?.status?.toUpperCase()}
          </Typography>

          <Typography sx={{ color: "black", fontSize: "10px" }}>
            Date: {props?.invoice?.createdAt?.split("T")[0]}
          </Typography>
          <Typography sx={{ color: "black", fontSize: "10px" }}>
            Time: {formatTime(props?.invoice?.createdAt)}
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          p: "5px",
          borderBottom: "1px solid #707070",
        }}
      >
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography sx={{ fontWeight: "500" }}>Item</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography sx={{ fontWeight: "500" }}>Type</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography sx={{ fontWeight: "500" }}>Qty</Typography>
                </TableCell>
                
                <TableCell align="right">
                  <Typography sx={{ fontWeight: "500" }}>Price</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props?.invoice?.products?.map((row, idx) => (
                <TableRow
                  key={idx}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                      <img
                        src={
                          row?.product_id?.thumbnail_image
                            ? `${row?.product_id?.thumbnail_image}`
                            : EmptyView
                        }
                        alt="prod"
                        style={{
                          outline: "1px solid #707070",
                          width: "25px",
                          height: "25px",
                          objectFit: "contain",
                          borderRadius: "2px",
                        }}
                      />
                      <Typography sx={{ fontSize: "12px" }}>
                        {row?.product_id?.productName ||
                          row?.product_id?.product_name}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell align="center">
                    <Typography sx={{ fontSize: "12px",textTransform:"uppercase",color:"#9DA3E0" }}>
                      {row?.variant || "default"}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography sx={{ fontSize: "12px" }}>
                      {row?.quantity}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography sx={{ fontSize: "12px" }}>
                      {
                      row?.product_id?.discount_price>0?
                      row?.product_id?.discount_price:
                      row?.product_id?.price}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Box
        sx={{
          p: "20px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography sx={{ color: "black", fontWeight: "500" }}>
            Total
          </Typography>
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography sx={{ color: "black", fontWeight: "500" }}>
            {props?.invoice?.total_price}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
});

export default InvoicePDF;
