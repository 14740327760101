import { Box, LinearProgress } from "@mui/material";
import React, { useState } from "react";
import Analytics from "./MainSectionComp/Analytics";
import MyStreams from "./MainSectionComp/MyStreams";
import Footer from "../Landing/Footer";

const MainSection = () => {
  const [loading, setLoading] = useState(false);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
        height: "100%",
        overflow: "visible",
        position: "relative",
      }}
    >
      {loading && (
        <LinearProgress
          sx={{ position: "absolute", top: "-10px", width: "100%" }}
        />
      )}

      <Box
        sx={{
          overflowY: "auto",
          "&::-webkit-scrollbar": {
            width: "5px",
            borderRadius: "50px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#646669",
            borderRadius: "50px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            backgroundColor: "#FDC532",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "#fff",
            borderRadius: "50px",
          },
        }}
        className="scrollable-content"
      >
        {/* top analytics */}
        <Analytics loading={loading} setLoading={setLoading} />

        {/* My streams */}
        <MyStreams />
        {/* footer */}

        {/* <Footer/> */}
      </Box>
    </Box>
  );
};

export default MainSection;
