import React from "react";

const PasswordResetSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="60"
      viewBox="0 0 89 89"
    >
      <g
        id="Group_549"
        data-name="Group 549"
        transform="translate(-976 -143.734)"
      >
        <circle
          id="Ellipse_58"
          data-name="Ellipse 58"
          cx="44.5"
          cy="44.5"
          r="44.5"
          transform="translate(976 143.734)"
          fill="#707070"
          opacity="0.14"
        />
        <circle
          id="Ellipse_57"
          data-name="Ellipse 57"
          cx="32.5"
          cy="32.5"
          r="32.5"
          transform="translate(988 155.734)"
          fill="#322f2f"
        />
        <path
          id="Icon_feather-key"
          data-name="Icon feather-key"
          d="M33.837,3,30.591,6.246M18.24,18.6a8.926,8.926,0,1,1-12.625,0,8.926,8.926,0,0,1,12.625,0Zm0,0,6.67-6.67m0,0L29.779,16.8l5.68-5.68L30.591,6.246m-5.68,5.68,5.68-5.68"
          transform="translate(1001.135 170.015)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          stroke-linejoin="round"
          strokeWidth="3"
        />
      </g>
    </svg>
  );
};

export default PasswordResetSvg;
