import React from "react";

const GoogleAuthSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21.015"
      height="20.775"
      viewBox="0 0 21.015 20.775"
    >
      <g
        id="Group_272"
        data-name="Group 272"
        transform="translate(-9.963 -9.612)"
      >
        <g id="google-pay-svgrepo-com" transform="translate(0.963 -2.476)">
          <path
            id="Path_14"
            data-name="Path 14"
            d="M25.9,19.445a12,12,0,0,0-.185-2.112H15.605v4H21.4a4.8,4.8,0,0,1-2.141,3.157v2.6h3.456A10,10,0,0,0,25.9,19.445Z"
            transform="translate(4.115 3.268)"
            fill="#4285f4"
            fillRule="evenodd"
          />
          <path
            id="Path_15"
            data-name="Path 15"
            d="M19.282,28.113a10.456,10.456,0,0,0,7.106-2.507l-3.456-2.6A6.581,6.581,0,0,1,19.282,24a6.393,6.393,0,0,1-6.018-4.288H9.7V22.39A10.763,10.763,0,0,0,19.282,28.113Z"
            transform="translate(0.438 4.751)"
            fill="#34a853"
            fillRule="evenodd"
          />
          <path
            id="Path_16"
            data-name="Path 16"
            d="M13.7,22.267a6.04,6.04,0,0,1,0-3.976V15.615h-3.56a10.1,10.1,0,0,0,0,9.328Z"
            transform="translate(0 2.197)"
            fill="#fbbc04"
            fillRule="evenodd"
          />
          <path
            id="Path_17"
            data-name="Path 17"
            d="M19.282,16.2A5.924,5.924,0,0,1,23.4,17.759h0l3.06-2.965a10.487,10.487,0,0,0-7.173-2.705A10.763,10.763,0,0,0,9.7,17.812l3.56,2.676A6.393,6.393,0,0,1,19.282,16.2Z"
            transform="translate(0.438 0)"
            fill="#ea4335"
            fillRule="evenodd"
          />
        </g>
      </g>
    </svg>
  );
};

export default GoogleAuthSvg;
