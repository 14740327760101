import React from "react";

const TwitterGraySvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="33"
      viewBox="0 0 33 33"
    >
      <g id="Group_511" data-name="Group 511" transform="translate(-0.275)">
        <circle
          id="Ellipse_53"
          data-name="Ellipse 53"
          cx="16.5"
          cy="16.5"
          r="16.5"
          transform="translate(0.275)"
          fill="#fff"
        />
        <path
          id="Icon_awesome-twitter"
          data-name="Icon awesome-twitter"
          d="M18.173,7.481c.013.18.013.36.013.54A11.73,11.73,0,0,1,6.375,19.832,11.731,11.731,0,0,1,0,17.968a8.588,8.588,0,0,0,1,.051,8.314,8.314,0,0,0,5.154-1.774,4.159,4.159,0,0,1-3.881-2.879,5.235,5.235,0,0,0,.784.064,4.391,4.391,0,0,0,1.092-.141A4.152,4.152,0,0,1,.823,9.216V9.164A4.181,4.181,0,0,0,2.7,9.691,4.158,4.158,0,0,1,1.414,4.139a11.8,11.8,0,0,0,8.56,4.344,4.686,4.686,0,0,1-.1-.951,4.155,4.155,0,0,1,7.185-2.84,8.173,8.173,0,0,0,2.635-1,4.14,4.14,0,0,1-1.825,2.288,8.322,8.322,0,0,0,2.391-.643,8.924,8.924,0,0,1-2.082,2.146Z"
          transform="translate(6.647 4.893)"
          fill="#322f2f"
        />
      </g>
    </svg>
  );
};

export default TwitterGraySvg;
