import { toast } from "react-toastify";
import axios from "./axios";

export const getAllCities = async () => {
  try {
    return await axios.get(`/leopard/getAllCities`);
  } catch (error) {
    toast.error("Error: Something went wrong. ");
  }
};

export const bookPacket = async (data) => {
  try {
    return await axios.post(`/leopard/bookPacket`, data);
  } catch (error) {
    // toast.error("Error: Something went wrong. ");
  }
};

export const getAllBanks = async () => {
  try {
    return await axios.get(`/leopard/getAllBanks`);
  } catch (error) {
    toast.error("Error: Something went wrong. ");
  }
};

export const addShiper = async (data) => {
  try {
    return await axios.post(`/leopard/createShipper`, data);
  } catch (error) {
    // toast.error("Error: Something went wrong. ");
    toast.error(`Error: ${error?.response?.data?.message}`);
  }
};

export const updateShipperBankInfo = async (data) => {
  try {
    return await axios.post(`/leopard/updateShipperBankInfo`, data);
  } catch (error) {
    // toast.error("Error: Something went wrong. ");
  }
};

export const trackBookedPacket = async (data) => {
  try {
    return await axios.post(`/leopard/trackBookedPacket`, data);
  } catch (error) {
    toast.error("Error: Something went wrong. ");
  }
};

export const cancelBookedPackets = async (data) => {
  try {
    return await axios.post(`/leopard/cancelBookedPackets`, data);
  } catch (error) {
    toast.error("Error: Something went wrong. ");
  }
};
