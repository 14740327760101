import React from "react";

const ResetDoneSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="89"
      height="89"
      viewBox="0 0 89 89"
    >
      <g
        id="Group_549"
        data-name="Group 549"
        transform="translate(-976 -143.734)"
      >
        <circle
          id="Ellipse_58"
          data-name="Ellipse 58"
          cx="44.5"
          cy="44.5"
          r="44.5"
          transform="translate(976 143.734)"
          fill="#109922"
          opacity="0.14"
        />
        <circle
          id="Ellipse_57"
          data-name="Ellipse 57"
          cx="32.5"
          cy="32.5"
          r="32.5"
          transform="translate(988 155.734)"
          fill="#109922"
        />
        <path
          id="Icon_awesome-check"
          data-name="Icon awesome-check"
          d="M12.227,30.9.527,19.2a1.8,1.8,0,0,1,0-2.546L3.073,14.1a1.8,1.8,0,0,1,2.546,0L13.5,21.986,30.382,5.1a1.8,1.8,0,0,1,2.546,0L35.473,7.65a1.8,1.8,0,0,1,0,2.546l-20.7,20.7A1.8,1.8,0,0,1,12.227,30.9Z"
          transform="translate(1002.5 170.234)"
          fill="#fff"
        />
      </g>
    </svg>
  );
};

export default ResetDoneSvg;
