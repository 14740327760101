import { Box, Button, Container, Typography } from "@mui/material";
import React, { useEffect } from "react";
// import { getFeaturedProduct } from "../../api/Product.api";
import { useLocation, useNavigate } from "react-router-dom";
import { getFeaturedProduct } from "../../api/ProductApis";

const FeaturedProduct = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const [featuredProduct, setFeaturedProduct] = React.useState([]);

  let query = "";
  if (window.location.hostname && window.location.pathname === "/") {
    query = window.location.hostname.split(".")[0];
  } else if (search) {
    query = new URLSearchParams(search).get("q");
  }

  const fetchFeaturedProduct = async () => {
    const response = await getFeaturedProduct(query);

    if (response?.status === 200) {
      let data = response.data.featuredProducts;
      setFeaturedProduct([...data]);
    }
  };

  useEffect(() => {
    fetchFeaturedProduct();
  }, []);

  return (
    <Container maxWidth="lg">
      {featuredProduct?.length > 0 && (
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column-reverse", md: "row" },
            gap: 2,
            py: "30px",
          }}
        >
          <Box
            sx={{
              py: "20px",
              display: "flex",
              flexDirection: "column",
              alignItems: "left",
              width: { xs: "100%", md: "50%" },
            }}
          >
            <Typography
              variant="h3"
              sx={{ color: "#596271", fontWeight: "bold" }}
            >
              Featured Product
            </Typography>

            <Typography
              component="h4"
              noWrap
              sx={{
                color: "#596271",
                fontWeight: "bold",
              }}
            >
              {featuredProduct[0]?.product_name}
            </Typography>

            <Typography
              noWrap
              sx={{
                color: "#596271",
              }}
              dangerouslySetInnerHTML={{ __html: featuredProduct[0]?.description }}
            />
              {/* {featuredProduct[0]?.description} */}
              
         

            <Typography
              variant="h4"
              sx={{ color: "#FE9B0E", py: "30px", fontWeight: "bold" }}
            >
              Rs{featuredProduct[0]?.price}/-
            </Typography>

            <Button
              sx={{
                background: "#FE9B0E",
                color: "#fff",
                px: "20px",
                width: "fit-content",
                ":hover": { background: "#FE9B0E" },
              }}
              onClick={() => navigate(`product/${featuredProduct[0]._id}`)}
            >
              Order Here
            </Button>
          </Box>
          <Box
            sx={{
              width: { xs: "calc(100% - 20px)", md: "50%" },
              background: "#CDDEE4",
              height: "300px",
              p: "10px",
            }}
          >
            <img
              src={featuredProduct[0]?.images[0]}
              alt="prod"
              style={{ width: "100%", objectFit: "contain", height: "100%" }}
            />
          </Box>
        </Box>
      )}
    </Container>
  );
};

export default FeaturedProduct;
