export const cities = [
  {
    id: "1",
    name: "Abbottabad",
    shipment_type: ["OVERLAND", "DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "3",
    name: "BAGNOTER",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "5",
    name: "GOHARABAD",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "7",
    name: "HARNO",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "9",
    name: "JHUGIAN",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "10",
    name: "JINNAHABAD",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "11",
    name: "KAKOOL ( PMA)",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "12",
    name: "KALA BAGH (P.A.",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "16",
    name: "MALIK PURA",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "17",
    name: "MANDIAN",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "20",
    name: "MUSLIMABAD",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "23",
    name: "NAWAN SHER",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "26",
    name: "REHMATABAD",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "31",
    name: "SHIMLA HILL",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "35",
    name: "Attock",
    shipment_type: ["OVERNIGHT", "OVERLAND", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "36",
    name: "ATTOCK KHURD",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "39",
    name: "BROTHA",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "42",
    name: "GHAZI",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "44",
    name: "GONDAL",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "49",
    name: "HATTIAN",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "50",
    name: "Hazro",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "51",
    name: "Kamra",
    shipment_type: ["OVERNIGHT", "OVERLAND", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "53",
    name: "LAWRENCEPUR",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "54",
    name: "MALAN MANSOOR",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "56",
    name: "MANSHERA CAMP",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "63",
    name: "RANGO",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "71",
    name: "SHINKA",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "72",
    name: "Tarbela Dam",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "73",
    name: "WAISA",
    shipment_type: ["OVERNIGHT", "OVERLAND", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "74",
    name: "Badin",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "75",
    name: "GOLARCHI",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "77",
    name: "KHOSKI",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "78",
    name: "Matli",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "79",
    name: "TALHAR",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "80",
    name: "TANDO BAGO",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "81",
    name: "TANDO GHULAM AL",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "82",
    name: "Tando Mohd.khan",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "83",
    name: "Bahawalnagar",
    shipment_type: ["OVERNIGHT", "OVERLAND", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "84",
    name: "Chistian",
    shipment_type: ["OVERNIGHT", "OVERLAND", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "85",
    name: "Haroonabad",
    shipment_type: ["DETAIN", "OVERNIGHT", "OVERLAND"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "88",
    name: "CHAK ABDULLAH",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "90",
    name: "DHARANWALA",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "92",
    name: "Faqir Wali",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "93",
    name: "FORT ABBAS",
    shipment_type: ["OVERNIGHT", "OVERLAND", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "95",
    name: "MANDI SADIQ GUN",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "96",
    name: "MINCHIN ABAD",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "97",
    name: "MOHAR SHARIF",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "99",
    name: "Bahawalpur",
    shipment_type: ["OVERNIGHT", "OVERLAND", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "100",
    name: "Ahmad Pur East",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "101",
    name: "Dunya Pur",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "102",
    name: "KAHROR PAKKA",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "103",
    name: "KHAIRPUR TAMIAN",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "104",
    name: "Lodhran",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "105",
    name: "NOORPUR NORANGA",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "106",
    name: "SATIYANA",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "107",
    name: "SYED WALA",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "108",
    name: "UCH SHARIF",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "109",
    name: "Yazman Mandi",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "110",
    name: "Bannu",
    shipment_type: ["OVERLAND", "DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "111",
    name: "MIRAN SHAH",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "112",
    name: "DOMAIL",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "113",
    name: "Lakki Marwat",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "117",
    name: "SARAI NAURANG",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "118",
    name: "Batkhela",
    shipment_type: ["OVERLAND", "DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "119",
    name: "ABOHA",
    shipment_type: ["OVERNIGHT", "OVERLAND", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "121",
    name: "AMAN DARA",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "123",
    name: "BARIKOT",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "124",
    name: "CHAKDARA",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "125",
    name: "DARBAR(HAJIABAD",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "126",
    name: "DHERI JULAGRAM",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "127",
    name: "GULABAD",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "129",
    name: "KHAR (BATKHELA)",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "130",
    name: "KOTA",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "131",
    name: "Malakand",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: false,
    allow_as_destination: true,
  },
  {
    id: "134",
    name: "Bhai Pheru",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "135",
    name: "Manga Mandi",
    shipment_type: ["DETAIN", "OVERNIGHT", "OVERLAND"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "136",
    name: "CHANGA MANGA",
    shipment_type: ["OVERNIGHT", "OVERLAND", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "137",
    name: "HEAD BALOKI ROA",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "140",
    name: "Bhakkar",
    shipment_type: ["DETAIN", "OVERNIGHT", "OVERLAND"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "142",
    name: "BEHAL",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "145",
    name: "DULEWALA",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "146",
    name: "HYDER ABAD THAL",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "149",
    name: "NAWAN JANDANWAL",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "153",
    name: "Bhimber A. K",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "154",
    name: "AMBRIALA CHOWK",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "155",
    name: "BARNALA (A.K)",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "156",
    name: "BHRING (A.K)",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "157",
    name: "CHOWKI (A.K)",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "158",
    name: "DHANDER (KALAN)",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "159",
    name: "DHOK DAURA (A.K",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "160",
    name: "KADHALA (A.K)",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "161",
    name: "KALARY MORE(A.K",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "164",
    name: "KOT JAMEL (A.K)",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "165",
    name: "LIAQATABAD(A.K)",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "168",
    name: "SMAHNI (A.K)",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "169",
    name: "BUNNER",
    shipment_type: ["DETAIN", "OVERNIGHT", "OVERLAND"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "170",
    name: "BAZARGAI",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "178",
    name: "Burewala",
    shipment_type: ["OVERNIGHT", "OVERLAND", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "179",
    name: "GAGGO MANDI",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "180",
    name: "Chakwal",
    shipment_type: ["OVERNIGHT", "OVERLAND", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "181",
    name: "AMIN ABAD",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "182",
    name: "AMIR PUR MANGAN",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "186",
    name: "BHAGWAL",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "187",
    name: "BHAUN",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "188",
    name: "BHIKARI KALAN",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "189",
    name: "BHUBBAR",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "191",
    name: "CHAK BAQAR SHAH",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "192",
    name: "CHAK BELI KHAN",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "198",
    name: "CHAMBI",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "199",
    name: "Choa Saidan Sha",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "201",
    name: "DAHEWAL",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "202",
    name: "DALWAL",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "206",
    name: "DHODA",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "207",
    name: "DHOKE BADIAL",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "208",
    name: "DHOKE MAKEN",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "209",
    name: "DHUDIAL",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "210",
    name: "DHURKANA",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "212",
    name: "DOREY",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "214",
    name: "KALAR KAHAR",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "215",
    name: "KERYALA",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "219",
    name: "MAGHAL",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "221",
    name: "MIANI",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "222",
    name: "MOHARA GULSHER",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "223",
    name: "MOHUTA MOHRA",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "224",
    name: "MULHAL MUGHALAN",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "225",
    name: "MURID",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "229",
    name: "PERIAL",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "230",
    name: "PINDI GUJRAN",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "234",
    name: "SALOI",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "236",
    name: "SARAI CHOWK",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "237",
    name: "SARKALAN",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "238",
    name: "SIRGUDHAN",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "239",
    name: "SOHAWA DEWALIAN",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "240",
    name: "TATRAL",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "241",
    name: "THANIL FATOI",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "245",
    name: "VEHALIZER",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "248",
    name: "Chicha Watni",
    shipment_type: ["OVERLAND", "DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "249",
    name: "GHAZIABAD",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "250",
    name: "HARAPPA STATION",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "252",
    name: "KAMALIA",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "254",
    name: "Chiniot",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "256",
    name: "Chitral",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "257",
    name: "DARROSH",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "258",
    name: "Dadu",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "259",
    name: "BHAN SAEED ABAD",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "260",
    name: "PHULJI STATION",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "262",
    name: "Sehwan",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "263",
    name: "SITA ROAD(REHMA",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "264",
    name: "Dadyal (a.k)",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "265",
    name: "AKAL GARH (A.K)",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "266",
    name: "BATHRUI (A.K)",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "267",
    name: "CHAK SWARI(A.K)",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "268",
    name: "DHANGRI BALA (A",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "269",
    name: "EISER (A.K)",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "270",
    name: "HAMID PUR (A.K)",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "271",
    name: "ISLAM GARH(A.K)",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "273",
    name: "KARKRA TOWN(A.K",
    shipment_type: ["OVERNIGHT", "OVERLAND", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "274",
    name: "KHANABAD (A.K)",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "275",
    name: "PANYAM (A.K)",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "276",
    name: "PIND KALAN(A.K)",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "277",
    name: "PIND KHURD(A.K)",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "278",
    name: "PLAK (A.K)",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "279",
    name: "SARANDA (A.K)",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "280",
    name: "TANGDEW (A.K)",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "281",
    name: "TRUTTA (A.K)",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "282",
    name: "Daska",
    shipment_type: ["DETAIN", "OVERNIGHT", "OVERLAND"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "283",
    name: "GLOTIAN MORR",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "284",
    name: "JAISERWALA",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "285",
    name: "KANDAL SAYAN",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "286",
    name: "Mandranwala",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "287",
    name: "MITRANWALI",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "289",
    name: "RANJHI",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "291",
    name: "Sohawa",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "293",
    name: "Dera Ghazi Khan",
    shipment_type: ["DETAIN", "OVERNIGHT", "OVERLAND"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "296",
    name: "CHOTI",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "297",
    name: "FAZILPUR DHUNDH",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "298",
    name: "Jampur",
    shipment_type: ["DETAIN", "OVERNIGHT", "OVERLAND"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "300",
    name: "KOT MITHAN",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "302",
    name: "MOHMMADPUR DIWA",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "304",
    name: "PAIGAH",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "305",
    name: "Rajanpur",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "306",
    name: "Dera Ismail Khan",
    shipment_type: ["DETAIN", "OVERNIGHT", "OVERLAND"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "314",
    name: "PAROVA",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "319",
    name: "TANK",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "322",
    name: "Faisalabad",
    shipment_type: ["DETAIN", "OVERNIGHT", "OVERLAND"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "323",
    name: "Khurrianwala",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "324",
    name: "CHAK JHUMRA",
    shipment_type: ["OVERNIGHT", "OVERLAND", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "325",
    name: "Sangla Hill",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "326",
    name: "GADOON AMAZAI",
    shipment_type: ["OVERNIGHT", "OVERLAND", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "327",
    name: "GILGIT",
    shipment_type: ["OVERLAND", "DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "329",
    name: "CHILAS",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "330",
    name: "ASTORE",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "331",
    name: "BHONE",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "333",
    name: "KARIMABAD(HUNZA",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: false,
    allow_as_destination: false,
  },
  {
    id: "334",
    name: "Gojra",
    shipment_type: ["OVERLAND", "DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "336",
    name: "ADDA PENSRA",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "337",
    name: "ADDA THIKRIWALA",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "344",
    name: "Gujar Khan",
    shipment_type: ["OVERLAND", "DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "348",
    name: "BEWAL",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "358",
    name: "DAUL TALA",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "363",
    name: "HARNAL",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "364",
    name: "ISLAM PURA JABB",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "365",
    name: "JAND NAGAR",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "368",
    name: "KALLAR SAYDIAN",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "371",
    name: "KANYAL",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "373",
    name: "Mandra",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "380",
    name: "MOHRA NOORI",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "382",
    name: "PAKKA KHUH",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "384",
    name: "Rawat",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "385",
    name: "SACOTE",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "386",
    name: "SAGRI",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "391",
    name: "SHAH BAGH",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "399",
    name: "Gujranwala",
    shipment_type: ["OVERNIGHT", "OVERLAND", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "400",
    name: "alipur chattha",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "401",
    name: "Hafizabad",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "402",
    name: "JALAL PUR BHATT",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "403",
    name: "Kamoki",
    shipment_type: ["OVERLAND", "DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "404",
    name: "NOWSHERA VIRKAN",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "405",
    name: "QILA DEDAR SING",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "407",
    name: "GHUMAN WALA",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "409",
    name: "JIBBRAN MANDI",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "412",
    name: "KOT JE SING",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "414",
    name: "KOULO TARRAR",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "416",
    name: "MURALI WALA",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "418",
    name: "Rahwali",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "419",
    name: "RAJA SADOKEY",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "420",
    name: "RASOOL PUR TARR",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "423",
    name: "VANKY TARER",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "426",
    name: "Gujrat",
    shipment_type: ["DETAIN", "OVERNIGHT", "OVERLAND"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "427",
    name: "BAHUWAL",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "432",
    name: "BOKEN MORE",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "434",
    name: "DAULATPUR SAFAN",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "435",
    name: "Fatehpur",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "436",
    name: "HARIYAWALA",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "438",
    name: "Kotla Arab Ali",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "444",
    name: "SABOOR SHARIF",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "445",
    name: "SAROKI",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "447",
    name: "Haripur",
    shipment_type: ["OVERLAND", "DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "452",
    name: "HATTAR IND. EST",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "453",
    name: "HAVELIAN",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "455",
    name: "KANGRA",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "456",
    name: "KHALABAT SECTOR",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "458",
    name: "KOT NAJEEB ULLA",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "464",
    name: "PANIAN",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "466",
    name: "PIND HASHIM KHA",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "469",
    name: "SARAI NAYMAT KH",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "475",
    name: "Hyderabad",
    shipment_type: ["DETAIN", "OVERNIGHT", "OVERLAND"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "476",
    name: "Tando Adam",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "477",
    name: "BHIT SHAH",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "478",
    name: "HALA",
    shipment_type: ["OVERLAND", "DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "479",
    name: "Jamshoro",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "481",
    name: "Kotri",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "482",
    name: "Matiyari",
    shipment_type: ["DETAIN", "OVERNIGHT", "OVERLAND"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "483",
    name: "SAEEDABAD",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "485",
    name: "Shadadpur",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "486",
    name: "Islamabad",
    shipment_type: ["OVERNIGHT", "OVERLAND", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "487",
    name: "BHARA KHU",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "488",
    name: "Jalal Pur Jattan",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "490",
    name: "BHAGOWAL KALAN",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "492",
    name: "HAJIWALA",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "493",
    name: "Karianwala",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "497",
    name: "MOHINUDIN PUR",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "498",
    name: "TANDA",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "499",
    name: "Jaranwala",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "500",
    name: "AWAGUT",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "501",
    name: "Jauhrabad",
    shipment_type: ["OVERNIGHT", "OVERLAND", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "509",
    name: "GROAT SHEHAR/CA",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "512",
    name: "KATTHA SUGHRAL",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "513",
    name: "KHABEKI",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "515",
    name: "KHORRA",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "516",
    name: "Khushab",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "519",
    name: "MITHA TIWANA",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "520",
    name: "NOORPUR THAL",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "521",
    name: "NOWSHERA DT. KH",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "524",
    name: "PADRAR",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "527",
    name: "QAIDABAD",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "530",
    name: "SHAHPUR CITY",
    shipment_type: ["OVERLAND", "DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "531",
    name: "SHAHPUR SADDAR",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "532",
    name: "VEGOWAL",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "533",
    name: "WADHI",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "536",
    name: "Jehangira",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "538",
    name: "AKORA KHATTAQ",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "539",
    name: "KHAIRABAD",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "541",
    name: "Jhang",
    shipment_type: ["DETAIN", "OVERNIGHT", "OVERLAND"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "542",
    name: "Ahmad Pur Sial",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "544",
    name: "CHUND",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "545",
    name: "GARH MORE",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "547",
    name: "MALHOONA MORE",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "548",
    name: "MANDI SHAH JUIN",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "550",
    name: "ROODO SULTAN",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "551",
    name: "Jhelum",
    shipment_type: ["OVERNIGHT", "OVERLAND", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "553",
    name: "BHOWANJ",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "557",
    name: "CHAK JAMAL",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "563",
    name: "Deena",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "565",
    name: "GUJARPUR",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "578",
    name: "PAKHWAL",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "579",
    name: "PANDORE",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "580",
    name: "PURAN",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "587",
    name: "Sarai Alamgir",
    shipment_type: ["OVERLAND", "DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "589",
    name: "SOHAWA (CITY ON",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "592",
    name: "Karachi",
    shipment_type: ["OVERNIGHT", "OVERLAND", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "594",
    name: "Gawadar",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "596",
    name: "Turbat",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "598",
    name: "Hub Chowki",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "600",
    name: "Panjgour",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: false,
    allow_as_destination: true,
  },
  {
    id: "601",
    name: "UTHAL",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "602",
    name: "WINDER",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "608",
    name: "BELA",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "619",
    name: "GIDANI",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "628",
    name: "Lasbela",
    shipment_type: ["OVERLAND", "DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "666",
    name: "Kark",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "670",
    name: "Kasur",
    shipment_type: ["OVERLAND", "DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "672",
    name: "MANDI USMANWALA",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "673",
    name: "KHAIR PUR MEERU",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "675",
    name: "Kandiaro",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "676",
    name: "KOT DIGEE",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "678",
    name: "Mehrabpur",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "679",
    name: "PIR JO GOTH",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "680",
    name: "Rani Pur",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "681",
    name: "THERI MIR WAH",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "683",
    name: "Khanewal",
    shipment_type: ["OVERNIGHT", "OVERLAND", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "685",
    name: "CHAK 168/10R",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "686",
    name: "JAHANIA",
    shipment_type: ["OVERNIGHT", "OVERLAND", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "688",
    name: "Kabir Wala",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "691",
    name: "PANG KASI",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "693",
    name: "SARDARPUR",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "694",
    name: "SHAMKOT",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "696",
    name: "THATTA (SADIQAB",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "697",
    name: "Khanpur",
    shipment_type: ["OVERNIGHT", "OVERLAND", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "701",
    name: "ZAHIR PEER",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "702",
    name: "Kharian",
    shipment_type: ["OVERLAND", "DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "704",
    name: "ATTOWALA",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "705",
    name: "BIDDER MARJAN",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "710",
    name: "DHORIA",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "711",
    name: "DHUNNI",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "712",
    name: "Dinga",
    shipment_type: ["OVERLAND", "DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "718",
    name: "NOONAWALI",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "722",
    name: "Khewra",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "723",
    name: "DANDOT",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "724",
    name: "Kohat",
    shipment_type: ["DETAIN", "OVERNIGHT", "OVERLAND"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "725",
    name: "HANGU",
    shipment_type: ["DETAIN", "OVERNIGHT", "OVERLAND"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "726",
    name: "ALI ZAI KURRAM",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "727",
    name: "BABRI BANDA",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "728",
    name: "BAGGAN",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "731",
    name: "CHAKAR KOT KOHA",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "734",
    name: "GUMBAT",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "738",
    name: "LACHI",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "739",
    name: "MANDURI KURRAM",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "741",
    name: "PARACHINAR",
    shipment_type: ["DETAIN", "OVERNIGHT", "OVERLAND"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "743",
    name: "SARO ZAI",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "744",
    name: "TAPPI",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "745",
    name: "THALL",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "747",
    name: "Kot Addu",
    shipment_type: ["OVERNIGHT", "OVERLAND", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "748",
    name: "DAIRA DIN PANNA",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "749",
    name: "MIR PUR BAGHAL",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "751",
    name: "SANAWAN",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "752",
    name: "Taunsa Sharif",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "754",
    name: "Kotli (a. K)",
    shipment_type: ["OVERNIGHT", "OVERLAND", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "755",
    name: "AGHAR JAMALPUR",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "757",
    name: "BRUND BATHA(A.K",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "758",
    name: "CHORAI (A.K)",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "759",
    name: "DAMMAS (A.K)",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "760",
    name: "DANDLI (A.K)",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "762",
    name: "DONGI (A.K)",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "763",
    name: "GOI",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "764",
    name: "GULPUR (A.K)",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "765",
    name: "HAJIABAD (A.K)",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "766",
    name: "HOLAR (A.K)",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "767",
    name: "JUNA (A.K)",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "768",
    name: "KALAH",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "769",
    name: "KAMROTTY (A.K)",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "770",
    name: "KERALA MAJHAN",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "771",
    name: "KHAD GUJRAN(A.K",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "772",
    name: "KHURATTA (A.K)",
    shipment_type: ["OVERLAND", "DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "773",
    name: "NAKIYAL (A.K)",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "774",
    name: "NAR (A.K.)",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "775",
    name: "NEW AFZALPUR(AK",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "777",
    name: "PANJEERA (A.K.)",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "778",
    name: "POTHA (A.K.)",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "779",
    name: "PULENDRI (A.K)",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "781",
    name: "SARSAWA (A.K.)",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "782",
    name: "SEHAR MANDI(A.K",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "783",
    name: "SEHNSA (A.K.)",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "785",
    name: "SUPPLY (A.K.)",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "787",
    name: "TALIAN (A.K.)",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "788",
    name: "TATA PANI (A.K)",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "789",
    name: "Lahore",
    shipment_type: ["DETAIN", "OVERNIGHT", "OVERLAND"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "790",
    name: "Muridke",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "805",
    name: "CHUNG",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "806",
    name: "Ferozwala",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "807",
    name: "KAHNA NAO",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "808",
    name: "KALA SHAH KAKU",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "809",
    name: "KOT ABDUL MALIK",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "810",
    name: "Shahdara",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "812",
    name: "Lalamusa",
    shipment_type: ["DETAIN", "OVERNIGHT", "OVERLAND"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "817",
    name: "Larkana",
    shipment_type: ["OVERLAND", "DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "818",
    name: "ARIJA",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "819",
    name: "BADAH",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "822",
    name: "DOKRI",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "823",
    name: "GARIH KHAIRO",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "825",
    name: "KAMBER ALI KHAN",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "826",
    name: "KHAIRPUR NATHAN",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "827",
    name: "LALU RAWANK",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "828",
    name: "Mehar",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "829",
    name: "MIROKHAN",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "831",
    name: "NAUDERO",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "833",
    name: "RADHAN",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "834",
    name: "RATODERO",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "835",
    name: "Shahdadkot",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "837",
    name: "WAGGON",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "838",
    name: "WARAH",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "839",
    name: "Layyah",
    shipment_type: ["OVERNIGHT", "OVERLAND", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "843",
    name: "FATEHPUR (CHAK",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "844",
    name: "HERA (CHAK 134",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "845",
    name: "JAMAN SHAH(SURS",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "846",
    name: "KAROR LAL EASAN",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "847",
    name: "KOT SULTAN(BHAI",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "848",
    name: "LADHANA",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "849",
    name: "LALA ZAR(CHAK 1",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "851",
    name: "PIR JAGI(CHAK 1",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "854",
    name: "JAUNPUR",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "855",
    name: "KHAN BELA",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "856",
    name: "Liaqatpur",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "857",
    name: "TRANDA MOHD. PA",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "858",
    name: "Mandi Bahauddin",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "859",
    name: "BADSHAHPUR",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "861",
    name: "BHIKI SHARIF",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "862",
    name: "BHIKO MORE",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "863",
    name: "GOJRA ( MANDI B",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "864",
    name: "HARIAH RAILWAY",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "866",
    name: "KUTHIALA SHEIKH",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "868",
    name: "LAIDHER",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "873",
    name: "PAHRIANWALI ADD",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "874",
    name: "PHALIA",
    shipment_type: ["OVERNIGHT", "OVERLAND", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "875",
    name: "QADIRABAD",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "878",
    name: "WASU",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "879",
    name: "MOHLANWAL",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "880",
    name: "Mansehra",
    shipment_type: ["OVERNIGHT", "OVERLAND", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "881",
    name: "ATTAR SHISHA",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "883",
    name: "BALAKOT",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "884",
    name: "Batgram",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "886",
    name: "BEESHAM",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "891",
    name: "GARHI HABIB ULL",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "892",
    name: "GHAZIKOT TOWNSH",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "893",
    name: "HAJIABAD ICHRIA",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "895",
    name: "NEWDARBAND TOWN",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "896",
    name: "OUGHI",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "897",
    name: "Qalandarabad",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "898",
    name: "SHANKIARI",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "900",
    name: "Mardan",
    shipment_type: ["DETAIN", "OVERNIGHT", "OVERLAND"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "901",
    name: "Charsadda",
    shipment_type: ["DETAIN", "OVERNIGHT", "OVERLAND"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "903",
    name: "BALA GARHI",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "904",
    name: "GARHI DOULAT ZA",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "905",
    name: "GUJAR GARHI",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "908",
    name: "JAMAL GARI",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "910",
    name: "KATLANG",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "911",
    name: "MAYAR",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "913",
    name: "RAJAR",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "914",
    name: "RASHAKAI",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "916",
    name: "SARDHERI",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "919",
    name: "SHEWA ADDA",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "921",
    name: "UMAR ZAI",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "923",
    name: "Mian Channu",
    shipment_type: ["OVERNIGHT", "OVERLAND", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "924",
    name: "ABDUL HAKIM",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "925",
    name: "AGWANA",
    shipment_type: ["DETAIN", "OVERNIGHT", "OVERLAND"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "926",
    name: "ARIFABAD",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "930",
    name: "KOT ISLAM",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "931",
    name: "KOT SUJAN SING",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "932",
    name: "MAKADOOMPUR POK",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "934",
    name: "PULL BAGER",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "935",
    name: "PULL NO. 12 MEL",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "938",
    name: "TULAMBA",
    shipment_type: ["OVERLAND", "DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "939",
    name: "Mianwali",
    shipment_type: ["OVERNIGHT", "OVERLAND", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "940",
    name: "BALOKHEL CITY",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "941",
    name: "CHASHMA",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "944",
    name: "DAUDKHEL",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "945",
    name: "EASAKHEL",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "946",
    name: "GULAN KHEL",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "949",
    name: "KALA BAGH",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "950",
    name: "KALOR KOT",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "951",
    name: "KOT CHANDNAN",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "952",
    name: "KUNDIAN",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "953",
    name: "LIAQATABAD(PIPL",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "956",
    name: "PHAKI SHAH MARD",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "964",
    name: "Mingora (swat)",
    shipment_type: ["OVERLAND", "DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "966",
    name: "CHAR BAGH (SWAT",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "968",
    name: "GUMBAT MERA",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "969",
    name: "JOR (BUNNER)",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "974",
    name: "MATTA (SWAT)",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "975",
    name: "SAIDU SHARIF (S",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "976",
    name: "Mirpur (a. K)",
    shipment_type: ["OVERNIGHT", "DETAIN", "OVERLAND"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "977",
    name: "CHECHIAN (A.K.)",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "978",
    name: "CHITTAR PARI",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "979",
    name: "JATLAN (A.K.)",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "980",
    name: "KHALIQ ABAD(A.K",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "981",
    name: "Mangla Hamlet (a.k.)",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "982",
    name: "MANGLA DAM",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "984",
    name: "PANDI SABARWAL",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "985",
    name: "PULL MANDA (A.K",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "986",
    name: "TARIQ ABAD (A.K",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "988",
    name: "Mirpur Khas",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "989",
    name: "BACHA BAND",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "990",
    name: "DIGRI",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "991",
    name: "HINGORNO",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "992",
    name: "JHUDO",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "993",
    name: "KHIPRO",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "994",
    name: "KOT GHULAM MOHD",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "995",
    name: "Kunri",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "997",
    name: "MITHI",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "999",
    name: "PITHORO",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1000",
    name: "SAMARO",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1001",
    name: "TANDO JAN MOHD.",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1002",
    name: "THARPARKER",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1003",
    name: "Umer Kot",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1004",
    name: "Mirpur Mathelo",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1005",
    name: "Ghotki",
    shipment_type: ["DETAIN", "OVERLAND", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1006",
    name: "ADIL PUR",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1007",
    name: "DAD LAGHARI",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1008",
    name: "Dharki",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1009",
    name: "JARWAR",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1010",
    name: "KHANPUR MEHER",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1012",
    name: "Moro",
    shipment_type: ["DETAIN", "OVERLAND", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1015",
    name: "MITHIANI",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1016",
    name: "NEW JATOI",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1017",
    name: "Multan",
    shipment_type: ["DETAIN", "OVERLAND", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1018",
    name: "JHARIAN",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1019",
    name: "Narang Mandi",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1020",
    name: "Murree",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1025",
    name: "BHURBAN PEARL C",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1036",
    name: "LOWER TOPA",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1039",
    name: "SEHER BAGLA",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1043",
    name: "Muzaffarabad(ak)",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1044",
    name: "AMBOR AREA",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1045",
    name: "Bagh (a.k.)",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1047",
    name: "CHAKOTHI (A.K.)",
    shipment_type: ["OVERNIGHT", "DETAIN", "OVERLAND"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1048",
    name: "CHAMANKOT (A.K)",
    shipment_type: ["OVERNIGHT", "DETAIN", "OVERLAND"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1049",
    name: "CHATTAR AREA",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1050",
    name: "CHELLA BANDI (A",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1052",
    name: "DHANI BOMBIAN",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1054",
    name: "EIDGAH ROAD",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1055",
    name: "GARHI DOPATTA",
    shipment_type: ["OVERLAND", "OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1056",
    name: "HARI GHEL",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1058",
    name: "JALABAD (A.K.)",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1059",
    name: "KOHRI (A.K.)",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1062",
    name: "NOMANPURA",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1063",
    name: "PATTIKA (A.K.)",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1064",
    name: "RANGLA (A.K.)",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1065",
    name: "SARRAN (A.K.)",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1066",
    name: "SHOUKAT LINES",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1067",
    name: "Muzaffargarh",
    shipment_type: ["OVERNIGHT", "DETAIN", "OVERLAND"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1068",
    name: "Alipur",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1071",
    name: "Chowk Sarwar Sh",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1073",
    name: "Khangarh",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1074",
    name: "KHANPUR SHOMALI",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1075",
    name: "LALPIR (THERMA",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1076",
    name: "Mehmoodkot",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1077",
    name: "QADIRPUR RAWAN",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1078",
    name: "QASBA GRT. CHAK",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1082",
    name: "narowal",
    shipment_type: ["OVERNIGHT", "DETAIN", "OVERLAND"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1084",
    name: "ALIPUR SAYYADAN",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1087",
    name: "SHAKAR GARH",
    shipment_type: ["OVERLAND", "OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1088",
    name: "ZAFARWAL",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1089",
    name: "Nawab Shah",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1090",
    name: "SANGHAR",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1091",
    name: "AKRI",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1092",
    name: "BUCHERI",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1093",
    name: "DAUR",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1094",
    name: "KAROONDI",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1095",
    name: "KHADRO",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1096",
    name: "PACCA CHANG",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1097",
    name: "QAZI AHMAD",
    shipment_type: ["OVERNIGHT", "DETAIN", "OVERLAND"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1098",
    name: "SAKRAND",
    shipment_type: ["OVERNIGHT", "DETAIN", "OVERLAND"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1099",
    name: "SHAH PUR CHAKAR",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1100",
    name: "REHMANI NAGAR",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1101",
    name: "Nowshera",
    shipment_type: ["DETAIN", "OVERLAND", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1110",
    name: "KHAT KALI",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1112",
    name: "PAR NOWSHERA",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1114",
    name: "Risal Pur",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1115",
    name: "TARNAB FARM(AGR",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1117",
    name: "DARBELLO",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1118",
    name: "DARYA KHAN MARI",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1120",
    name: "Nowshero Feroz",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1123",
    name: "THARU SHAH",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1124",
    name: "Okara",
    shipment_type: ["OVERLAND", "OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1125",
    name: "BASSER PUR",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1126",
    name: "Depalpur",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1127",
    name: "HUJRA SHAH MUKE",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1128",
    name: "MANDI HEERA SIN",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1129",
    name: "RENALAKHURD",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1131",
    name: "CHAWINDA",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1132",
    name: "KALASWALA",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1133",
    name: "Pasrur",
    shipment_type: ["DETAIN", "OVERLAND", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1135",
    name: "Pattoki",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1137",
    name: "KANGANPUR",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1138",
    name: "TALVANDI",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1139",
    name: "THEENG MORE(ALL",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1140",
    name: "Peshawar",
    shipment_type: ["DETAIN", "OVERLAND", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1142",
    name: "DARA ADAM KHEL",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1143",
    name: "SHABQADAR",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1145",
    name: "Pind Dadan Khan",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1148",
    name: "JALALPUR SHARIF",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1151",
    name: "Quetta",
    shipment_type: ["OVERLAND", "OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1152",
    name: "Khuzdar",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1153",
    name: "PISHIN",
    shipment_type: ["OVERNIGHT", "DETAIN", "OVERLAND"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1154",
    name: "CHAMAN",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1156",
    name: "KALAT",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1157",
    name: "LORALAI",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1158",
    name: "MASTUNG",
    shipment_type: ["OVERLAND", "OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1159",
    name: "NUSHKI",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1160",
    name: "Zhob",
    shipment_type: ["OVERLAND", "OVERNIGHT", "DETAIN"],
    allow_as_origin: false,
    allow_as_destination: true,
  },
  {
    id: "1161",
    name: "AWARAN",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1162",
    name: "JIWANI",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1163",
    name: "KHARAN",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1165",
    name: "MACH",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1167",
    name: "MUSLIM BAGH",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1168",
    name: "OREMARA TOWN",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1169",
    name: "Rabwa",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1170",
    name: "Rahim Yar Khan",
    shipment_type: ["DETAIN", "OVERLAND", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1171",
    name: "Raiwind",
    shipment_type: ["OVERNIGHT", "DETAIN", "OVERLAND"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1172",
    name: "KOT RADHA KISHA",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1173",
    name: "Rawalakot (a.k)",
    shipment_type: ["DETAIN", "OVERLAND", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1174",
    name: "ABBASPUR (A.K)",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1175",
    name: "AWAN ABAD(BATHI",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1176",
    name: "AZIZ ABAD (A.K.",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1177",
    name: "BLOOUCH (A.K.)",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1179",
    name: "CHAK BAZAAR(A.K",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1181",
    name: "CHOTTAGLA (A.K)",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1183",
    name: "GALA KNATHA",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1184",
    name: "HAJIRA (A.K.)",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1186",
    name: "JUNDATHI (A.K)",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1187",
    name: "KHAIGALA (A.K.)",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1188",
    name: "KHARICK",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1191",
    name: "MONG (A.K.)",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1192",
    name: "MUJAHID ABAD (A",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1193",
    name: "NAKKAH BAZAR(A.",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1195",
    name: "PANYIOLA (A.K.)",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1196",
    name: "QILLAN (A.K.)",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1197",
    name: "SINGOLA (A.K.)",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1198",
    name: "TANGI GALA (A.",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1199",
    name: "THORAR (A.K.)",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1200",
    name: "TOPA (A.K.)",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1201",
    name: "TRARKHAIL (A.K)",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1202",
    name: "Rawalpindi",
    shipment_type: ["DETAIN", "OVERLAND", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1203",
    name: "Fateh Jang",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1204",
    name: "Jand",
    shipment_type: ["OVERLAND", "OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1205",
    name: "Pindi Gheb",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1209",
    name: "CHAKLALA",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1213",
    name: "IKHLAS (DIST. A",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1215",
    name: "Kahuta",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1216",
    name: "KHORE",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1227",
    name: "Tarnol",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1228",
    name: "Sadiqabad",
    shipment_type: ["OVERNIGHT", "DETAIN", "OVERLAND"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1230",
    name: "GOTH MACHI",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1232",
    name: "KASHMOOR",
    shipment_type: ["OVERNIGHT", "DETAIN", "OVERLAND"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1235",
    name: "SUI",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1236",
    name: "Sahiwal",
    shipment_type: ["DETAIN", "OVERLAND", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1237",
    name: "Arifwala",
    shipment_type: ["DETAIN", "OVERLAND", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1238",
    name: "Pakpattan",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1239",
    name: "BONGA HAYAT",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1241",
    name: "NOOR SHAH",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1243",
    name: "QABOOLA SHARIF",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1244",
    name: "Samundri",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1246",
    name: "ADDA PHLOOR ONL",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1247",
    name: "DIJKOT",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1251",
    name: "KHIDAR WALA",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1252",
    name: "MAMON KANJAN",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1254",
    name: "MURID WALA",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1258",
    name: "TANDLIANWALA",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1260",
    name: "Sargodha",
    shipment_type: ["OVERLAND", "OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1262",
    name: "ALI PUR SYEDAN",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1263",
    name: "BHAGATANWALA",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1264",
    name: "Bhalwal",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1265",
    name: "BHERA",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1266",
    name: "FAROOQA",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1267",
    name: "HAZOOR PUR",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1269",
    name: "Kot Momin",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1270",
    name: "Lallian",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1272",
    name: "NEHANG",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1274",
    name: "PHULLARWAN",
    shipment_type: ["OVERLAND", "OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1275",
    name: "PULL 111 CHAK",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1276",
    name: "RADHEN",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1277",
    name: "SAHIWAL (NAWAN",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1278",
    name: "Sial Sharif",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1279",
    name: "SILLANWALI",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1280",
    name: "Sheikhupura",
    shipment_type: ["DETAIN", "OVERLAND", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1283",
    name: "BURG ATTARI",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1284",
    name: "DERA MALLA SIN",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1286",
    name: "FAIZPUR KHURD",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1287",
    name: "Farooqabad",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1288",
    name: "Feroz Watwan",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1290",
    name: "KHAN GAH DOGRAN",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1293",
    name: "Manawala",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1294",
    name: "MANDI DHABA SIN",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1296",
    name: "MANDI SAFDAR AB",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1299",
    name: "Nankana Sahib",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1300",
    name: "Pindi Bhattian",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1302",
    name: "SAFDARABAD",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1303",
    name: "Shahkot",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1304",
    name: "SHARAQPUR SHARI",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1306",
    name: "KHAN KA SHARIF",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1307",
    name: "Shujabad",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1308",
    name: "JALALPUR PIRWAL",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1309",
    name: "Sialkot",
    shipment_type: ["OVERNIGHT", "DETAIN", "OVERLAND"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1311",
    name: "BHOPALWALA",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1313",
    name: "GHUENKE",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1314",
    name: "JHETEKE",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1316",
    name: "KOTLI LOHARAN",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1318",
    name: "QILA SAIFULLAH",
    shipment_type: ["OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1321",
    name: "Sambrial",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1323",
    name: "SKARDU",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: false,
    allow_as_destination: false,
  },
  {
    id: "1324",
    name: "Jacobabad",
    shipment_type: ["OVERNIGHT", "DETAIN", "OVERLAND"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1325",
    name: "Shikarpur",
    shipment_type: ["OVERLAND", "OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1326",
    name: "Sukkur",
    shipment_type: ["OVERNIGHT", "DETAIN", "OVERLAND"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1328",
    name: "Dera ala yar",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1329",
    name: "Dera Murad Jama",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1330",
    name: "DHADAR",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1331",
    name: "GAMBAT",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1332",
    name: "GARHI KHERO",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1333",
    name: "GARHI YASIN",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1335",
    name: "HALANI",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1336",
    name: "HARNAI",
    shipment_type: ["DETAIN", "OVERLAND", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1340",
    name: "JOHI",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1341",
    name: "KANDHKOT",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1342",
    name: "KHAN PUR DISTT.",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1346",
    name: "KOT DEJI",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1349",
    name: "LAKHI GHULAM SH",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1353",
    name: "Pano Aqil",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1356",
    name: "Rohri",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1359",
    name: "SIBBI",
    shipment_type: ["DETAIN", "OVERLAND", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1360",
    name: "Sultan Kot",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1361",
    name: "THEHRI",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1362",
    name: "THULL",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1363",
    name: "USTA MUHAMMAD",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1364",
    name: "SWABI",
    shipment_type: ["OVERLAND", "OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1366",
    name: "KALABAT",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1367",
    name: "KHOTA",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1370",
    name: "MARGAZ",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1372",
    name: "TAND KOI",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1373",
    name: "TOPI",
    shipment_type: ["OVERLAND", "OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1376",
    name: "Takht-e-Bhai",
    shipment_type: ["DETAIN", "OVERLAND", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1377",
    name: "DARGAI",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1378",
    name: "HARI CHAND",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1380",
    name: "JALALA",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1384",
    name: "Talagang",
    shipment_type: ["OVERLAND", "OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1399",
    name: "Tamirgaraha",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1400",
    name: "Dir",
    shipment_type: ["OVERLAND", "OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1403",
    name: "DARRORA",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1404",
    name: "HAJI ABAD",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1405",
    name: "HAYA SERAI",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1406",
    name: "KHAAL",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1407",
    name: "KHAR (BAJORE AG",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1411",
    name: "SAHIB ABAD",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1412",
    name: "SHER KHANIE",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1415",
    name: "Tando ala yar",
    shipment_type: ["DETAIN", "OVERLAND", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1416",
    name: "Tando Jam",
    shipment_type: ["OVERNIGHT", "DETAIN", "OVERLAND"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1417",
    name: "Thatha",
    shipment_type: ["OVERLAND", "OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1418",
    name: "CHOHAR JAMALI",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1419",
    name: "DHABEJI",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1420",
    name: "Gharo",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1421",
    name: "MAKLI",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1422",
    name: "MIR PUR SAKRO",
    shipment_type: ["OVERNIGHT", "DETAIN", "OVERLAND"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1423",
    name: "SUJAWAL",
    shipment_type: ["OVERLAND", "OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1424",
    name: "Toba Tek Sing",
    shipment_type: ["DETAIN", "OVERLAND", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1426",
    name: "Pirmahal",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1427",
    name: "RAJANA",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1428",
    name: "SANDHIANWALI",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1429",
    name: "Shorkot Cantt.",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1434",
    name: "Vehari",
    shipment_type: ["OVERLAND", "OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1435",
    name: "ADDA PAKHI MORE",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1436",
    name: "DOKOTA",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1438",
    name: "Hasilpur",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1439",
    name: "LUDDAN",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1440",
    name: "MACHIWAL",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1441",
    name: "Mailsi",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1442",
    name: "THAINGI",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1443",
    name: "TIBBA SULTAN PU",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1444",
    name: "Wah Cantt",
    shipment_type: ["OVERNIGHT", "DETAIN", "OVERLAND"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1446",
    name: "Hassan Abdal",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1448",
    name: "POURMIANA",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1449",
    name: "Taxila",
    shipment_type: ["DETAIN", "OVERLAND", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1450",
    name: "Wazirabad",
    shipment_type: ["OVERNIGHT", "DETAIN", "OVERLAND"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1451",
    name: "ADDA AUJLA KALA",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1452",
    name: "AHMAD NAGAR",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1453",
    name: "Elahabad",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1454",
    name: "AZIZ CHAK",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1456",
    name: "Gakkhar Mandi",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1459",
    name: "KARMABAD",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1460",
    name: "KHAIW WALI",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1462",
    name: "Nizamabad",
    shipment_type: ["OVERNIGHT", "DETAIN", "OVERLAND"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1679",
    name: "NOORIABAD",
    shipment_type: ["DETAIN", "OVERLAND", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1681",
    name: "DOLTA",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1684",
    name: "SHER SHAH",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1685",
    name: "MIRUPR BATHORO",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1687",
    name: "HAYATABAD",
    shipment_type: ["DETAIN", "OVERLAND", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1709",
    name: "BADOMALHI",
    shipment_type: ["OVERNIGHT", "DETAIN", "OVERLAND"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1713",
    name: "QILA KALLARWALA",
    shipment_type: ["OVERNIGHT", "DETAIN", "OVERLAND"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1741",
    name: "JHAWARIYA",
    shipment_type: ["OVERNIGHT", "DETAIN", "OVERLAND"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1753",
    name: "MOUCH",
    shipment_type: ["OVERLAND", "OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1768",
    name: "BHABRRA",
    shipment_type: ["OVERNIGHT", "DETAIN", "OVERLAND"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1786",
    name: "ZIABAD",
    shipment_type: ["OVERLAND", "OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "1803",
    name: "SARA-E- KARISHAN",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "2504",
    name: "HABIBABAD",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "2505",
    name: "DHURNAL",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "2506",
    name: "FAQIRABAD",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "2507",
    name: "SANGHI",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "2509",
    name: "DAULAT PUR",
    shipment_type: ["DETAIN", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "2521",
    name: "MALAKWAL",
    shipment_type: ["OVERLAND", "OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "2603",
    name: "Ghous Pur",
    shipment_type: ["OVERNIGHT", "DETAIN", "OVERLAND"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "2604",
    name: "Sobho Dero",
    shipment_type: ["OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "2605",
    name: "Chak",
    shipment_type: ["OVERNIGHT", "DETAIN", "OVERLAND"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "2632",
    name: "SHADUN LUND",
    shipment_type: ["OVERLAND", "OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "2633",
    name: "KARAM PUR",
    shipment_type: ["DETAIN", "OVERLAND", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "2643",
    name: "JAMAL PUR",
    shipment_type: ["OVERNIGHT", "DETAIN", "OVERLAND"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "2644",
    name: "DHANOT",
    shipment_type: ["OVERLAND", "OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "2652",
    name: "MUBARAK PUR",
    shipment_type: ["DETAIN", "OVERLAND", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "2653",
    name: "DERA BAKHA",
    shipment_type: ["OVERNIGHT", "DETAIN", "OVERLAND"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "2661",
    name: "JAFFARABAD",
    shipment_type: ["OVERNIGHT", "DETAIN", "OVERLAND"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "2675",
    name: "CHOWK MUNDA",
    shipment_type: ["OVERNIGHT", "DETAIN", "OVERLAND"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "2677",
    name: "MITRO",
    shipment_type: ["OVERNIGHT", "DETAIN", "OVERLAND"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "2693",
    name: "JAMAL DIN WALI",
    shipment_type: ["OVERNIGHT", "DETAIN", "OVERLAND"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "4016",
    name: "JHAMPEER",
    shipment_type: ["OVERNIGHT", "DETAIN", "OVERLAND"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "4021",
    name: "ISLAMKOT",
    shipment_type: ["DETAIN", "OVERLAND", "OVERNIGHT"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "9013",
    name: "Khairpur",
    shipment_type: ["OVERNIGHT", "DETAIN", "OVERLAND"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
  {
    id: "9019",
    name: "Ghari Khero",
    shipment_type: ["OVERLAND", "OVERNIGHT", "DETAIN"],
    allow_as_origin: true,
    allow_as_destination: true,
  },
];
