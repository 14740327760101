import React, { createContext, useEffect, useState } from "react";
import { getBusinessUser } from "../api/AuthApis";
import { getTemplates, storeTemplate } from "../api/templates";


export const StreamingContext = createContext();

const initialObj = {
  facebook: null,
  instagram: null,
  youtube: null,
  in_app: null,
  likedComments: [],
  displayedComments: [],
  isFacebookActive: false,
  isFacebookRTMPActive: false,
  isInstagramActive: false,
  isYoutubeActive: false,
  isInAppActive: false,
  isSocialMediaDetails: false,
  instaDetails: false,
  youtubeDetails: false,
  facebookDetails: false,
  faceBookRTMPDetails: false,

};

const graphicObj = {
  bannerLowerThird: null,
  lowerThird: null,
  intro: null,
  ticker: null,
  logo: [],
  products: null,
  ads: null,
  isCommentHide: false,
};
const facebookObjec = {
  username: "",
  userId: "",
  selectedPage:localStorage.getItem('fbSelectedPage') || '',
  pageAccessToken: "",
  pageId: "",
  title: "",
  description: "",
  streaming_link: "",
  videoId: "",
  facebookLive: false,
};


const instaObjec = {
  rtmpUrl: "",
  isInstagramLive: false,
};

const youtubeObjec = {
  title: "",
  description: "",
  streaming_link: "",
  liveChatId: "",
  youtubeLive: false,
};

export const StreamingProvider = ({ children }) => {
// const localStorageSelectedPage = localStorage.getItem('fbSelectedPage') || '';

  const [streamData, setStreamData] = useState(initialObj);
  const [graphicsData, setgraphicsData] = useState(graphicObj);
  const [allPages, setAllPages] = React.useState([]);
  const [facebookObj, setFacebookObj] = React.useState(facebookObjec);
  const [instaObj, setInstaObj] = React.useState(instaObjec);
  const [youtubeObj, setYoutubeObj] = React.useState(youtubeObjec);
  const [chatHistory, setChatHistory] = React.useState([]);
  const [liveStreamActive, setLiveStreamActive] = React.useState(false);
  const [isOnMain, setIsOnMain] = React.useState(true);
  const [disabledComments, setDisabledComments] = React.useState(false);
  const [oauthSessionExpired, setOauthSessionExpired] = React.useState(false);
  const [socialDrawer, setSocialDrawer] = React.useState(false);
  const [optionToggleMenu,setOptionToggleMenu]=React.useState(false);
  const [menuResp,setMenuResp]=React.useState(false);
  const [recordedStreamsModal, setRecordedStreamsModal] = useState(false);
  const [crossPostPages, setCrossPostPages] = useState([]);
  const [loadingCrossPostPages, setLoadingCrossPostPages] = useState([]);

  const handleStreamModal = () => {
    setRecordedStreamsModal(!recordedStreamsModal);
  }
  const HandleMenuResp=()=>{
    setMenuResp(!menuResp);
  }
  const [platforms, setPlatforms] = React.useState({
    facebook: false,
    instagram: false,
    youtube: false,
    in_app: false,
  });
  const [inAppLiveUrl, setInAppLiveUrl] = React.useState("");

  const [graphicState, setGraphicState] = useState({
    openedSection: "logo",
  });

  const [prodState, setProdState] = useState({
    openedSection: "list",
  });
  // toggleDrawer
  const SocialtoggleDrawer = () => {
    setSocialDrawer(!socialDrawer);
  };
  // toggle option menu handler
  const HandleOptiontoggleMenu=()=>{
    // console.log("handler option menu called ")
    setOptionToggleMenu(!optionToggleMenu)
  }

  const [state, setState] = useState({
    expand: false,
    commentAnchor: null,
    commentSwitchAnchor: null,

    openedSection: "main",
  });

  // Check local storage for stored Facebook response (Will use it for other social media as well)
  useEffect(() => {
    // Check local storage for stored Facebook response
    const storedFacebookResponse = localStorage.getItem("facebookResponse");
    // const storedYoutubeResponse = localStorage.getItem(
    //   "youtubeStreamingResponse"
    // );
    const youtubeResponse = JSON.parse(localStorage.getItem("YoutubeResponse"));
    const storedInstagramResponse = localStorage.getItem(
      "instagramStreamingResponse"
    );

    if (storedFacebookResponse) {
      // Update the Facebook response in the context
      setStreamData((prevData) => ({
        ...prevData,
        facebook: JSON.parse(storedFacebookResponse),
      }));
    }

    if (youtubeResponse) {
      // Update the youtube response in the context
      setStreamData((prevData) => ({
        ...prevData,
        // youtube: JSON.parse(storedYoutubeResponse),
        youtube: youtubeResponse,
      }));
    }

    if (storedInstagramResponse) {
      // Update the instagram response in the context
      setStreamData((prevData) => ({
        ...prevData,
        instagram: JSON.parse(storedInstagramResponse),
      }));
    }
  }, []);

  // * set graphics
  // useEffect(() => {
  //   let product = JSON.parse(sessionStorage.getItem("productGraphics"));
  //   let logo = JSON.parse(sessionStorage.getItem("logoGraphics"));
  //   let lowerThird = JSON.parse(sessionStorage.getItem("lowerThirdGraphics"));
  //   let intro = JSON.parse(sessionStorage.getItem("IntroGraphics"));
  //   let ticker = JSON.parse(sessionStorage.getItem("TickerGraphics"));
  //   setgraphicsData({
  //     ...graphicsData,
  //     products: product === null ? null : {
  //       ... product
  //     },
  //     lowerThird: lowerThird === null ? null : {
  //       ... lowerThird
  //     },
  //     intro: intro === null ? null : {
  //       ... intro
  //     },
  //     ticker: ticker === null ? null : {
  //       ... ticker
  //     },
  //     logo: logo === null ? [] : [
  //       {
  //         ...logo
  //       }
  //     ]
  //   })

  // }, [isOnMain])

  const handleGraphicsData = (type, value) => {
    setgraphicsData({ ...graphicsData, [type]: value });
  };

  const changeInGraphicsHandler = async () => {
    try {
      let data = {
        isLogo: graphicsData.logo.length > 0 ? true : false,
        logoUrl: graphicsData.logo.length > 0 ? graphicsData.logo[0].image : "",
        logoAspectRatio:
          graphicsData.logo.length > 0 ? graphicsData.logo[0].aspectRatio : "",
        logoHeight:
          graphicsData.logo.length > 0 ? graphicsData.logo[0].height : "",
        logoMargin:
          graphicsData.logo.length > 0 ? graphicsData.logo[0].margin : "",
        logoOpacity:
          graphicsData.logo.length > 0 ? graphicsData.logo[0].opacity : "",
        logoPosition:
          graphicsData.logo.length > 0 ? graphicsData.logo[0].position : "",
        isIntro: graphicsData.intro ? true : false,
        introBgColor: graphicsData.intro ? graphicsData.intro.background : "",
        introTextColor: graphicsData.intro ? graphicsData.intro.textColor : "",
        introFontSize: graphicsData.intro ? graphicsData.intro.fontSize : "",
        introFontFamily: graphicsData.intro
          ? graphicsData.intro.fontFamily
          : "",
        isLowerThird: graphicsData.lowerThird ? true : false,
        lowerThirdTextColor: graphicsData.lowerThird
          ? graphicsData.lowerThird.textColor
          : "",
        lowerFontSize: graphicsData.lowerThird
          ? graphicsData.lowerThird.fontSize
          : "",
        lowerFontFamily: graphicsData.lowerThird
          ? graphicsData.lowerThird.fontFamily
          : "",
        lowerThirdPosition: graphicsData.lowerThird
          ? graphicsData.lowerThird.position
          : "",
        introTitle: graphicsData.intro ? graphicsData.intro.title : "",
        introSubTitle: graphicsData.intro ? graphicsData.intro.subtitle : "",
        lowerThirdTitle: graphicsData.lowerThird
          ? graphicsData.lowerThird.topLine
          : "",
      };

      const response = await storeTemplate(data);
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  // * useEffect that will check if any change in graphicsData then call the backend api

  useEffect(() => {
    // * don't call if page is reload
    if (isOnMain === false) {
      // changeInGraphicsHandler(); // ! issue in real time update
      console.log("change in graphics");
      console.log(graphicsData);
    }
  }, [graphicsData]);

  useEffect(() => {
    // ! issue in real time update
    // getTemplates().then((res) => {
    //   console.log(res.data.templates);
    //   if (res.data.templates) {
    //     let data = res.data.templates;
    //     let logo = [];
    //     if (data.isLogo) {
    //       logo.push({
    //         image: data.logoUrl,
    //         aspectRatio: data.logoAspectRatio,
    //         height: data.logoHeight,
    //         margin: data.logoMargin,
    //         opacity: data.logoOpacity,
    //         position: data.logoPosition,
    //       });
    //     }
    //     setgraphicsData({
    //       ...graphicsData,
    //       logo: logo,
    //       intro: data.isIntro
    //         ? {
    //             background: data.introBgColor,
    //             textColor: data.introTextColor,
    //             fontSize: data.introFontSize,
    //             fontFamily: data.introFontFamily,
    //             title: data.introTitle,
    //             subtitle: data.introSubTitle,
    //           }
    //         : null,
    //       lowerThird: data.isLowerThird
    //         ? {
    //             textColor: data.lowerThirdTextColor,
    //             fontSize: data.lowerFontSize,
    //             fontFamily: data.lowerFontFamily,
    //             topLine: data.lowerThirdTitle,
    //             position: data.lowerThirdPosition,
    //           }
    //         : null,
    //     });
    //   }
    // });
  }, []);

  // * clear streaming session if access token is expired
  useEffect(() => {
    const checkAndRemoveToken = () => {
      const tokenData = JSON.parse(localStorage.getItem("StreamingTimeout"));
      if (tokenData) {
        const now = new Date();
        const tokenTimestamp = new Date(tokenData);

        const timeDifference = now - tokenTimestamp;
        const twoHoursInMilliseconds = 1 * 60 * 60 * 1000; // 1 hour
        // const twoHoursInMilliseconds = 5 * 1000;

        if (timeDifference >= twoHoursInMilliseconds) {
          localStorage.removeItem("YoutubeResponse");
          localStorage.removeItem("StreamingTimeout");
          setOauthSessionExpired(true);
          console.log("Token has expired and been removed.");
        } else {
          console.log("Token is still valid.");
        }
      } else {
        localStorage.removeItem("YoutubeResponse");
        setOauthSessionExpired(true);
        console.log("No token found in localStorage.");
      }
    };

    checkAndRemoveToken();

    const interval = setInterval(() => {
      checkAndRemoveToken();
    }, 10 * 60 * 1000); // 10 minutes in milliseconds

    // Clear the interval when the component unmounts
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <StreamingContext.Provider
      value={{
        streamData,
        setStreamData,
        graphicsData,
        setgraphicsData,
        handleGraphicsData,
        allPages,
        setAllPages,
        facebookObj,
        setFacebookObj,
        instaObj,
        setInstaObj,
        youtubeObj,
        setYoutubeObj,
        chatHistory,
        setChatHistory,
        liveStreamActive,
        setLiveStreamActive,
        isOnMain,
        setIsOnMain,
        disabledComments,
        setDisabledComments,
        platforms,
        setPlatforms,
        state,
        setState,
        graphicState,
        setGraphicState,
        prodState,
        setProdState,
        oauthSessionExpired,
        setOauthSessionExpired,
        inAppLiveUrl, setInAppLiveUrl,
        SocialtoggleDrawer,
        socialDrawer,
        setSocialDrawer,
        HandleOptiontoggleMenu,
        optionToggleMenu,
        HandleMenuResp,
        menuResp,
        handleStreamModal,
        recordedStreamsModal,
        crossPostPages, setCrossPostPages,
        loadingCrossPostPages, setLoadingCrossPostPages
      }}
    >
      {children}
    </StreamingContext.Provider>
  );
};