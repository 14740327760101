import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  InputBase,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import MailIcon from "@mui/icons-material/Mail";
import LockIcon from "@mui/icons-material/Lock";
import FacebookAuthSvg from "../../assets/Svg/FacebookAuthSvg";
import GoogleAuthSvg from "../../assets/Svg/GoogleAuthSvg";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { loginBusinessUser, registerBusinessUser } from "../../api/AuthApis";
import { AuthContext } from "../../context/AuthContext";
import { useGoogleLogin } from "@react-oauth/google";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
const Login = () => {
  const { saveToken, saveUserData } = useContext(AuthContext);
  const [isAccepted, setAccepted] = useState(false);
  const [regError, setErr] = useState("")
  const navigate = useNavigate();
  const handleCheckboxChange = (event) => {
    setAccepted(event.target.checked);
  };
  const [credientials, setCredientials] = useState({
    email: "",
    password: "",
    loading: false,
    isVisible: false
  });

  const handleLogin = async () => {
    if (!credientials.email || !credientials.password) {
      toast.error("Error: Check email and password before logging in.");
      return;
    }
    
    if (!isAccepted) {
      setErr("Please accept terms and conditions")
      return;
    }

    let requestData = {
      password: credientials.password,
      provider: "custom",
    }

    // check credentials.email is email or phone number
    if (credientials.email.includes('@')) {
      requestData.email = credientials.email
    } else {
      const phoneRegex = /^\+\d{12}$/;
      if (!phoneRegex.test(credientials.email)) {
        toast.error("Error: Phone number is invalid.");
        return;
      }
      requestData.phone_number = credientials.email
    }

    setCredientials({ ...credientials, loading: true });

    const resp = await loginBusinessUser(requestData);

    if (resp?.status === 200) {
      toast.success(" Success: Login successful.");
      console.log("resp:", resp)
      saveToken(resp?.data?.token);
      saveUserData(resp.data);
      window.location.href = "/streaming";
      navigate("/streaming");
    }
    if (resp?.status === 400) {
      console.log("in cond:", resp?.data?.message)
      if(resp?.data?.message === "User is not verified") {
        sessionStorage.setItem("email", requestData.email)
        sessionStorage.setItem("phone_number", requestData.phone_number)
        sessionStorage.setItem("accountNotVerified", "true");
        navigate("/auth/register")
      }
      setErr(resp?.data?.message)
    }
    setCredientials({ ...credientials, loading: false });
  };

  const onSuccess = async (tokenResponse) => {
    const { access_token } = tokenResponse;

    // Make API call to fetch user's profile information
    const profileResponse = await fetch(
      "https://www.googleapis.com/oauth2/v1/userinfo?alt=json",
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    );

    const profileData = await profileResponse.json();
    const { email, id, name, given_name, family_name } = profileData;

    const resp = await loginBusinessUser({
      email: email,
      password: id,
      provider: "social",
    });

    if (resp?.status === 200) {
      toast.success(" Success: Login successful. ");
      saveToken(resp?.data?.token);
      saveUserData(resp.data);
      window.location.href = "/streaming";
      // navigate("/streaming");
    }
    else {
      const resp = await registerBusinessUser({
        name: given_name + family_name,
        email: email,
        password: id,
        provider: "social",
      });

      if (resp?.status === 201) {
        toast.success(" Success: Login successful. ");
        saveToken(resp?.data?.token);
        saveUserData(resp.data);

        navigate("/streaming");
      }
    }
  };

  const handleGoogleAuth = useGoogleLogin({
    onSuccess: onSuccess,
  });

  const handleFacebookAuth = async () => {
    try {
      window.FB.login(
        function (response) {
          if (response.authResponse) {
            window.FB.api(
              "/me",
              { fields: "name,email" },
              async function (response) {
                const { id } = response;
                const email = id.toString() + "@facebook.com";

                const resp = await loginBusinessUser({
                  email: email,
                  password: id,
                  provider: "social",
                });

                if (resp?.status === 200) {
                  toast.success(" Success: Login successful. ");
                  saveToken(resp?.data?.token);
                  saveUserData(resp.data);
                  window.location.href = "/streaming";
                  // navigate("/streaming");
                }
                else {
                  const resp = await registerBusinessUser({
                    name: response.name,
                    email: email,
                    password: id,
                    provider: "social",
                  });

                  if (resp?.status === 201) {
                    toast.success(" Success: Login successful. ");
                    saveToken(resp?.data?.token);
                    saveUserData(resp.data);

                    navigate("/streaming");
                  }
                }
              }
            );
          } else {
            console.log("User cancelled login or did not fully authorize.");
          }
        },
        { scope: "email" }
      );
    } catch (error) {
      return error;
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: 3,
      }}
    >
      <Box>
        <Typography
          sx={{ fontWeight: "600", fontSize: "20px", display: "flex" }}
        >
          READY
          <span style={{ fontWeight: "500", fontSize: "20px" }}>
            &nbsp;TO DIVE IN?
          </span>
        </Typography>
        <Typography sx={{ fontSize: "12px", color: "#8E8E8E" }}>
          Enter your email and password to get started.
        </Typography>
      </Box>
      <Box>
        <Box
          sx={{
            px: "8px",
            py: "5px",
            borderRadius: "5px",
            border: "1px solid #B7B7B7",
            lineHeight: "0",
            display: "flex",
            alignItems: "center",
            gap: 2,
          }}
        >
          <MailIcon sx={{ color: "#B7B7B7" }} />
          <InputBase
            type="text"
            autoComplete="new-mail"
            placeholder="Email or whatsapp no."
            sx={{
              ".css-yz9k0d-MuiInputBase-input": { p: "0" },
              width: "100%",
            }}
            value={credientials.email}
            onChange={(e) =>
              setCredientials({ ...credientials, email: e.target.value })
            }
          />
        </Box>
        {regError && regError === 'Email does not exist' ? (
          <Typography style={{ color: '#FF0000', fontWeight: '400', fontSize: "12px", margin: "4px", marginBottom: "0" }}>{regError}</Typography>
        ) : ''}
      </Box>
      <Box>
        <Box
          sx={{
            px: "8px",
            py: "5px",
            borderRadius: "5px",
            border: "1px solid #B7B7B7",
            lineHeight: "0",
            display: "flex",
            alignItems: "center",
            gap: 2,
          }}
        >
          <LockIcon sx={{ color: "#B7B7B7" }} />
          <InputBase
            type={credientials.isVisible ? "text" : "password"}
            autoComplete="new-password"
            placeholder="Password"
            sx={{
              ".css-yz9k0d-MuiInputBase-input": { p: "0" },
              width: "100%",
              background: "#fff",
            }}
            value={credientials.password}
            onChange={(e) =>
              setCredientials({ ...credientials, password: e.target.value })
            }
          />
          {credientials.isVisible ?
           <VisibilityIcon sx={{ color: "#B7B7B7",cursor:"pointer" }} onClick={() => setCredientials({ ...credientials, isVisible: false })} /> :
          <VisibilityOffIcon sx={{ color: "#B7B7B7",cursor:"pointer" }} onClick={() => setCredientials({ ...credientials, isVisible: true })} />
}

        </Box>
        {regError && regError === 'Password is incorrect' ? (
          <Typography style={{ color: '#FF0000', fontWeight: '400', fontSize: "12px", margin: "4px", marginBottom: "0" }}>{regError}</Typography>
        ) : ''}

      </Box>
      
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Button
          variant="contained"
          color="secondary"
          sx={{ borderRadius: "20px" }}
          onClick={handleLogin}
          disabled={credientials.loading}
        >
          {credientials.loading ? (
            <CircularProgress size={"25px"} sx={{ color: "#fff" }} />
          ) : (
            "Sign In"
          )}
        </Button>
  
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <FormControlLabel
            control={<Checkbox size="small" />}
            label={
              <Typography sx={{ fontSize: "12px", color: "#939393" }}>
                Remember me
              </Typography>
            }
          />

          <Link to={"/auth/reset"} style={{ textDecoration: "none" }}>
            <Typography sx={{ fontSize: "12px", color: "#0079F2E8" }}>
              Forgot your password?
            </Typography>
          </Link>
        </Box>
        <Box>

<FormControlLabel
sx={{mt:"4px"}}
  control={<Checkbox size="small" checked={isAccepted} onChange={handleCheckboxChange} />}
  label={
    <Typography
      sx={{
        fontSize: "12px",
        color:  "#939393",
      }}
    >
     By clicking Sign In you agree to our Terms of Use 
     <span style={{ color: "#0079F2E8" }} onClick={()=>navigate('/terms-of-service')}> Terms & Conditions </span>
      and <span style={{ color: "#0079F2E8" }} onClick={()=>navigate('/privacy')}> Privacy Policy</span>
     
    </Typography>
  }
/>
{regError && regError === 'Please accept terms and conditions' ? (
  <Typography style={{ color: '#FF0000', fontWeight: '400', fontSize: "12px", margin: "4px", marginBottom: "0" }}>{regError}</Typography>
) : ''}
</Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 1,
        }}
      >
        <Divider
          sx={{
            width: "40%",
          }}
        />
        <Typography sx={{ color: "#615E5E" }}>Or</Typography>
        <Divider
          sx={{
            width: "40%",
          }}
        />
      </Box>

      <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        <Typography sx={{ color: "#615E5E", mx: "auto" }}>
          Continue login with
        </Typography>

        <Box
          sx={{
            display: "flex",
            gap: 1,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {/* <Button
            sx={{
              minWidth: "30px",
              height: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              m: "0",
              p: "0",
            }}
            onClick={handleFacebookAuth}
          >
            <FacebookAuthSvg />
          </Button> */}
          <Button
            sx={{
              minWidth: "30px",
              height: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              m: "0",
              p: "0",
            }}
            onClick={handleGoogleAuth}
          >
            <GoogleAuthSvg />
          </Button>
          {/* <img
            src={require("../../assets/Img/linkedAuth.png")}
            alt="linkedin"
            style={{ width: "40px" }}
          /> */}
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: { xs: "column", lg: "row" },
          gap: 1,
        }}
      >
        <Typography sx={{ fontSize: "12px", color: "#8E8E8E" }}>
          Don't have an account?
        </Typography>

        <Button
          variant="contained"
          color="primary"
          sx={{
            borderRadius: "20px",
            py: "3px",
            boxShadow: "none",
            "&:hover": {
              background: "#FFBD54",
            },
          }}
          onClick={() => navigate("/auth/register")}
        >
          Sign Up
        </Button>
      </Box>
    </Box>
  );
};

export default Login;
