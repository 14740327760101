import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "./context/AuthContext";

export const ProtectedRoute = ({ Component }) => {
  const { authToken } = useContext(AuthContext);
  return authToken ? <Component /> : <Navigate to="/" />;
};

export const PublicRoute = ({ Component }) => {
  const { authToken } = useContext(AuthContext);
  return authToken ? <Navigate to="/" /> : <Component />;
};
