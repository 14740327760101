import { toast } from "react-toastify";
import axios from "./axios";

export const getStreamingData = async () => {
  return await axios.get(`/stream`);
};

export const updateStreamStatus = async (status) => {
  return await axios.put(`/stream/status`, {
    status,
  });
};

export const createYoutubeBroadcast = async (data) => {
  try {
    return await axios.post(`/stream/youtube/createBroadcast`, data);
  } catch (error) {
    return error;
  }
};

export const getLiveStreamChat = async (liveChatId) => {
  try {
    console.log(JSON.parse(localStorage.getItem("YoutubeResponse")))
    const response = await axios.get(`/stream/youtube/liveChats/${liveChatId}/${JSON.parse(localStorage.getItem("YoutubeResponse"))}`);
    return response;
  } catch (error) {
    return error;
  }
};

export const uploadStreamLogo = async (data) => {
  try {
    const response = await axios.post(`/stream/uploadstreamlogo`, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const toggleLiveStreamingStatus = async (data) => {
  try {
    const response = await axios.post(`/stream/live-streaming-status`, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const emitCommentSyncEvent = async () => {
  try {
    const response = await axios.post(`/liveComments/syncComments`);
    return response;
  }
  catch (error) {
    console.log(error);
    // toast.error("Error: Something went wrong. !");
  }
};

export const getAllLiveComments = async (videoId,chatId) => {
  try {
    const response = await axios.post(`/liveComments/getComments`,{
      videoId: videoId,
      chatId: chatId
    });
    return response;
  } catch (error) {
    console.log(error);
    // toast.error("Error: Something went wrong. !");
  }
};


// * auto comment sender
export const emitAutoCommentEvent = async (keyword, videoId, chatId) => {
  try {
    const response = await axios.post(`/liveComments/autoComment`,{
      keyword,
      videoId,
      chatId
    });
    return response;
  } catch (error) {
    console.log(error);
    // toast.error("Error: Something went wrong. !");
  }
};

// * storing streaming matrics
export const storeStreamingMatrics = async (data) => {
  try {
    const response = await axios.post(`/streamMatric`, data);
    return response;
  }
  catch (error) {
    console.log(error);
  }
};

// * getting all stream matrics data
export const getAllStreamMatrics = async () => {
  try {
    const response = await axios.get(`/streamMatric/all`);
    return response;
  } catch (error) {
    console.log(error);
  }
};

// * get today's stream matrics data
export const getTodayStreamMatrics = async () => {
  try {
    const response = await axios.get(`/streamMatric`);
    return response;
  } catch (error) {
    console.log(error);
  }
};

// * get filtered stream matrics data
export const getFilteredStreamMatrics = async (start_date, end_date) => {
  try {
    const response = await axios.get(`/streamMatric/filter?start_date=${start_date}&end_date=${end_date}`);
    return response;
  }
  catch (error) {
    console.log(error);
  }
};

// * get overall matrics data
export const getOverallMatrics = async () => {
  try {
    const response = await axios.get(`/streamMatric/overall`);
    return response;
  } catch (error) {
    console.log(error);
  }
};

// * create room for in app stream
export const createInAppStreamRoom = async (data) => {
  try {
    const response = await axios.post(`/inAppStream/createRoom`,data);
    return response;
  } catch (error) {
    return error;
  }
};

// * update room info in app stream
export const updateInAppStreamRoomStatus = async (data) => {
  try {
    const response = await axios.put(`/inAppStream/updateRoom`,data);
    return response;
  } catch (error) {
    return error;
  }
};

// * get room info from in app stream by RoomnName
export const getInAppStreamRoomInfo = async (roomName, is_active=false) => {
  try {
    const response = await axios.get(`/inAppStream/room/${roomName}?is_active=${is_active}`);
    return response;    
  } catch (error) {
    return error;
  }
};

// * get room info
export const getInAppStreamRoomAllInfo = async (roomName) => {
  try {
    const response = await axios.get(`/inAppStream/all-rooms/${roomName}`);
    return response;    
  } catch (error) {
    return error;
  }
};

// * get all Active Product listing for In App Live streaming
export const getInAppActiveProductListing = async (room) => {
  try {
    const response = await axios.get(`/inAppStream/products/${room}`);
    return response;    
  } catch (error) {
    return error;
  }
};

export const addStreamThumbnail = async (data) => {
  try {
    return await axios.post(`/inAppStream/thumbnail`, data);
  } catch (error) {
    toast.error("Error: Something went wrong. ");
  }
};

export const removeStreamThumbnail = async (imagePath) => {
  try {
    return await axios.delete(
      `/inAppStream/thumbnail?imagePath=${encodeURIComponent(imagePath)}`
    );
  } catch (error) {
    toast.error("Error: Something went wrong. ");
  }
};

export const getRandomStreams = async () => {
  try {
    return await axios.get(`/inAppStream/random-rooms`);
  } catch (error) {
    toast.error("Error: Something went wrong. ");
  }
};

export const getStreamCentralStreams = async () => {
  try {
    return await axios.get(`/inAppStream/stream-central`);
  } catch (error) {
    toast.error("Error: Something went wrong. ");
  }
};

export const getRecordedStreams = async (page) => {
  try {
    console.log("page", page)
    return await axios.get(`/inAppStream/stream-recorded?page=${page}`);
  } catch (error) {
    toast.error("Error: Something went wrong. ");
  }
};

export const getLiveStreams = async (page) => {
  try {
    return await axios.get(`/inAppStream/stream-live?page=${page}`);
  } catch (error) {
    toast.error("Error: Something went wrong. ");
  }
};

export const getUserRecordedStreams = async (page) => {
  try {
    return await axios.get(`/inAppStream/user-streams?page=${page}`);
  } catch (error) {
    toast.error("Error: Something went wrong. ");
  }
};
export const getStreamDetail = async (roomId) => {
  try {
    return await axios.get(`/inAppStream/stream/${roomId}`);
  } catch (error) {
    toast.error("Error: Something went wrong. ");
  }
};
export const UpdateStreamDetail = async (roomId,data) => {
  console.log("data",data)
  try {
    return await axios.put(`/inAppStream/stream/${roomId}`,data);
  } catch (error) {
    toast.error("Error: Something went wrong. ");
  }
};
export const deleteStream = async (roomId) => {
  try {
    return await axios.put(`/inAppStream/stream/delete/${roomId}`);
  } catch (error) {
    toast.error("Error: Something went wrong. ");
  }
};
// calling search stream api
export const searchStream = async (searchQuery) => {
  try {
    return await axios.get(`/inAppStream/search-filter?search=${searchQuery}`);
  } catch (error) {
    toast.error("Error: Something went wrong. ");
  }
};
// calling filter stream api
export const FilterStreams = async (filter) => {
  try {
    return await axios.get(`/inAppStream/search-filter?category==${filter}`);
  } catch (error) {
    toast.error("Error: Something went wrong. ");
  }
};
// streamin hub apis
export const getStreamHub = async (id,page) => {
  try {
    return await axios.get(`/inAppStream/stream/all-user-streams/${id}/?page=${page}`);
  } catch (error) {
    toast.error("Error: Something went wrong. ");
  }
};
// get store by user api
export const getStore = async (id) => {
  try {
    return await axios.get(`/store/store-by-user/${id}`);
  } catch (error) {
    console.log(error.message)
    // toast.error("Error: Something went wrong. ");
  }
};

export const updateOfflineMatrics = async (videoId) => {
  try {
    const response = await axios.post(`/liveComments/update-offline-matrics`,{
      videoId: videoId
    });
    return response;
  } catch (error) {
    console.log(error);
  }
}

// upload ad to the server 
export const uploadAd = async (data) => {
  try {
    const response = await axios.post(`/ads/create`, data);
    return response;
  } catch (error) {
    return error;
  }
};

// get all ads
export const getAllAds = async () => {
  try {
    const response = await axios.get(`/ads`);
    return response;
  } catch (error) {
    return error;
  }
};

// delete ad 
export const deleteAd = async (id) => {
  try {
    const response = await axios.delete(`/ads/delete/${id}`);
    return response;
  } catch (error) {
    return error;
  }
};

// get all facebook pages for crossposting
export const getCrosPostingPages = async () => {
  try {
    const response = await axios.get(`/crosspostfb/pages`);
    return response;
  } catch (error) {
    return error;
  }
};

// add page for crossposting
export const addCrossPostingPage = async (data) => {
  try {
    const response = await axios.post(`/crosspostfb/create`, {data});
    return response;
  } catch (error) {
    return error;
  }
};

// update offline comment trigger for cross post
export const updateOfflineCommentTrigger = async (data) => {
  try {
    const response = await axios.post(`/crosspostfb/update/offline-reply-trigger`, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const updateUrls = async (data) => {
  try {
    const response = await axios.put(`/inAppStream/updateUrls`, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const getLogos = async () => {
  try {
    const response = await axios.get(`/stream/logos`);
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteLogo = async (id) => {
  try {
    const response = await axios.delete(`/stream/delete-logo/${id}`);
    return response;
  } catch (error) {
    return error;
  }
};

export const updateLogoConfig = async (id, data) => {
  try {
    const response = await axios.put(`/stream/updateLogo/${id}`, data);
    return response;
  } catch (error) {
    return error;
  }
}

