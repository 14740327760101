import {
  Button,
  Container,
  FormControl,
  InputBase,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";

const GallerySection = ({
  searchQuery,
  setSearchQuery,
  sortProductsByPrice,
  handleSearch,
  removeSearch,
  isSearched,
  totalCount
}) => {
  const [sort, setSort] = React.useState("match");

  const handleChange = (event) => {
    setSort(event.target.value);
    sortProductsByPrice(event.target.value);
  };

  return (
    <Container maxWidth="lg" sx={{ marginBottom: "10px" }}>
      <Box
        sx={{
          borderBottom: "1px solid #D7D7D7",
          display: "flex",
          justifyContent: "space-between",
          py: "20px",
          flexDirection: { xs: "column", md: "row" },
          alignItems: "center",
        }}
      >
      
        <Typography
          variant="h5"
          sx={{
            fontWeight: "500",
            color: "#707070",
            my: "auto",
            width: "100%",
            textAlign: { xs: "center", md: "left" },
          }}
        >
          All Products <span style={{fontSize:"13px"}}> ({totalCount}) </span>
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "265px",
          }}
        >
          <FormControl
            sx={{ minWidth: 130, width: "100%", background: "#fff" }}
            size="small"
          >
            <InputLabel id="demo-select-small">Sort By</InputLabel>
            <Select
              labelId="demo-select-small"
              placeholder="All Categories"
              id="demo-select-small"
              value={sort}
              label="Sort By"
              onChange={handleChange}
              size="small"
            >
              <MenuItem value="match">Best Match</MenuItem>
              <MenuItem value={"highToLow"}>Price High to Low</MenuItem>
              <MenuItem value={"lowToHigh"}>Price Low to High</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
    </Container>
  );
};

export default GallerySection;
