import React from "react";

const InstaGraySvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="33"
      viewBox="0 0 33 33"
    >
      <g id="Group_512" data-name="Group 512" transform="translate(-0.138)">
        <circle
          id="Ellipse_52"
          data-name="Ellipse 52"
          cx="16.5"
          cy="16.5"
          r="16.5"
          transform="translate(0.138)"
          fill="#fff"
        />
        <path
          id="Icon_awesome-instagram"
          data-name="Icon awesome-instagram"
          d="M8.863,6.557A4.546,4.546,0,1,0,13.409,11.1,4.538,4.538,0,0,0,8.863,6.557Zm0,7.5A2.955,2.955,0,1,1,11.819,11.1,2.961,2.961,0,0,1,8.863,14.058Zm5.792-7.687a1.06,1.06,0,1,1-1.06-1.06A1.058,1.058,0,0,1,14.655,6.371Zm3.011,1.076a5.247,5.247,0,0,0-1.432-3.715A5.281,5.281,0,0,0,12.519,2.3c-1.464-.083-5.851-.083-7.315,0A5.274,5.274,0,0,0,1.489,3.728,5.264,5.264,0,0,0,.057,7.443c-.083,1.464-.083,5.851,0,7.315a5.247,5.247,0,0,0,1.432,3.715A5.288,5.288,0,0,0,5.2,19.9c1.464.083,5.851.083,7.315,0a5.247,5.247,0,0,0,3.715-1.432,5.281,5.281,0,0,0,1.432-3.715c.083-1.464.083-5.847,0-7.311Zm-1.891,8.882a2.992,2.992,0,0,1-1.685,1.685c-1.167.463-3.936.356-5.226.356s-4.063.1-5.226-.356a2.992,2.992,0,0,1-1.685-1.685C1.489,15.161,1.6,12.392,1.6,11.1s-.1-4.063.356-5.226A2.992,2.992,0,0,1,3.637,4.191c1.167-.463,3.936-.356,5.226-.356s4.063-.1,5.226.356a2.992,2.992,0,0,1,1.685,1.685c.463,1.167.356,3.936.356,5.226S16.238,15.165,15.775,16.328Z"
          transform="translate(7.776 5.398)"
          fill="#322f2f"
        />
      </g>
    </svg>
  );
};

export default InstaGraySvg;
