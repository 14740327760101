import { createBrowserRouter } from "react-router-dom";

// import * as Pages from "./pages";

import * as AuthComp from "./components/Auth";
import * as Storeflow from "./components/CreateStore";
import * as Dashboard from "./components/Dashboard";
import * as Shop from "./components/Shop";

import { AuthProvider } from "./context/AuthContext";
import { StoresProvider } from "./context/StoresContext";
import { StreamingProvider } from "./context/StreamingContext";
import { ShopProvider } from "./context/ShopContext";

import { ProtectedRoute, PublicRoute } from "./FilterRoute";
import { Suspense, lazy } from "react";
import { Box, CircularProgress } from "@mui/material";

import { SearchProvider } from "./context/SearchContext";

import { SocketProvider } from "./context/SocketContext";


const LazyAuth = lazy(() => import("./pages/Auth"));
const LazyDashboard = lazy(() => import("./pages/Dashboard"));
const LazyShop = lazy(() => import("./pages/Shop"));
const LazyLanding = lazy(() => import("./pages/Landing"));
const LazyLandingMain=lazy(()=>import("./pages/LandingMain"));
const LazyStreaming = lazy(() => import("./pages/Streaming"));
const LazyStoreCreation = lazy(() => import("./pages/StoreCreation"));
const LazyUserForm = lazy(() => import("./pages/UserForm"));
const LazyWhatsappCheckout = lazy(() => import("./pages/WhatsappCheckout"));
const LazyLiveStreams = lazy(() => import("./pages/LiveStreams"));
const LazyExploreStreams=lazy(()=>import("./pages/ExploreStreams"));
const LazyStores=lazy(()=>import("./pages/Stores"));
const PrivacyPolicy = lazy(() => import("./components/Landing/PrivacyPolicy"));
const LandingMain = lazy(() => import("./components/Landing/LandingMain"));
const ReDesignLandingMain=lazy(()=>import("./components/LandingMain/LandingMain.jsx"))
const AboutUs = lazy(() => import("./components/Landing/AboutUs"));
const TermsOfService = lazy(() =>
  import("./components/Landing/TermsOfService")
);

let loadCenter = {
  width: "100%",
  minHeight: "100vh",
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
};

export const appRouter = createBrowserRouter([
  {
    path: "/",
    element: (
      <AuthProvider userType={"businessUser"}>
        <Suspense
          fallback={
            <Box sx={loadCenter}>
              <CircularProgress color="secondary" />
            </Box>
          }
        >
          <LazyLanding />
        </Suspense>
      </AuthProvider>
    ),
    children: [
      {
        path: "",
        element: (
          <Suspense
            fallback={
              <Box sx={loadCenter}>
                <CircularProgress color="secondary" />
              </Box>
            }
          >
            <LandingMain />
          </Suspense>
        ),
      },
      {
        path: "privacy",
        element: (
          <Suspense
            fallback={
              <Box sx={loadCenter}>
                <CircularProgress color="secondary" />
              </Box>
            }
          >
            <PrivacyPolicy />
          </Suspense>
        ),
      },
      {
        path: "terms-of-service",
        element: (
          <Suspense
            fallback={
              <Box sx={loadCenter}>
                <CircularProgress color="secondary" />
              </Box>
            }
          >
            <TermsOfService />
          </Suspense>
        ),
      },
      {
        path: "about-us",
        element: (
          <Suspense
            fallback={
              <Box sx={loadCenter}>
                <CircularProgress color="secondary" />
              </Box>
            }
          >
            <AboutUs />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: "/mainlanding",
    element: (
      <AuthProvider userType={"businessUser"}>
        <Suspense
          fallback={
            <Box sx={loadCenter}>
              <CircularProgress color="secondary" />
            </Box>
          }
        >
          <LazyLandingMain />
        </Suspense>
      </AuthProvider>
    ),
    children: [
      {
        path: "",
        element: (
          <Suspense
            fallback={
              <Box sx={loadCenter}>
                <CircularProgress color="secondary" />
              </Box>
            }
          >
            <ReDesignLandingMain />
          </Suspense>
        ),
      },
      {
        path: "privacy",
        element: (
          <Suspense
            fallback={
              <Box sx={loadCenter}>
                <CircularProgress color="secondary" />
              </Box>
            }
          >
            <PrivacyPolicy />
          </Suspense>
        ),
      },
      {
        path: "terms-of-service",
        element: (
          <Suspense
            fallback={
              <Box sx={loadCenter}>
                <CircularProgress color="secondary" />
              </Box>
            }
          >
            <TermsOfService />
          </Suspense>
        ),
      },
      {
        path: "about-us",
        element: (
          <Suspense
            fallback={
              <Box sx={loadCenter}>
                <CircularProgress color="secondary" />
              </Box>
            }
          >
            <AboutUs />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: "/auth",
    element: (
      <AuthProvider userType={"businessUser"}>
        <Suspense
          fallback={
            <Box sx={loadCenter}>
              <CircularProgress color="secondary" />
            </Box>
          }
        >
          <PublicRoute Component={LazyAuth} />
        </Suspense>
      </AuthProvider>
    ),
    children: [
      {
        path: "login",
        element: <AuthComp.Login />,
      },
      {
        path: "register",
        element: <AuthComp.Register />,
      },
      {
        path: "reset",
        element: <AuthComp.Reset />,
      },
      {
        path: "reset/:token",
        element: <AuthComp.ResetToken />,
      },
    ],
  },
  {
    path: "/dashboard",
    element: (
      <AuthProvider userType={"businessUser"}>
        <StreamingProvider>
        <Suspense
          fallback={
            <Box sx={loadCenter}>
              <CircularProgress color="secondary" />
            </Box>
          }
        >
          <ProtectedRoute Component={LazyDashboard} />
        </Suspense>
        </StreamingProvider>
      </AuthProvider>
    ),
    children: [
      {
        path: "index",
        element: <Dashboard.MainSection />,
      },
      {
        path: "orders",
        element: <Dashboard.OrdersComp />,
      },
      {
        path: "products",
        element: <Dashboard.ProductsComp />,
      },
      {
        path: "upload-product",
        element: <Dashboard.UploadProduct />,
      },
      {
        path: "analytics",
        element: <>index</>,
      },
      {
        path: "settings",
        element: <Dashboard.SettingComp />,
      },
      {
        path: "help",
        element: <>index</>,
      },
    ],
  },
 
  {
    path: "/streaming",
    element: (
      <AuthProvider userType={"businessUser"}>
        <StreamingProvider>
          <Suspense
            fallback={
              <Box sx={loadCenter}>
                <CircularProgress color="secondary" />
              </Box>
            }
          >
            <ProtectedRoute Component={LazyStreaming} />
          </Suspense>
        </StreamingProvider>
      </AuthProvider>
    ),
  },
 
  {
    path: "/store",
    element: (
      <AuthProvider userType={"businessUser"}>
        {" "}
        <Suspense
          fallback={
            <Box sx={loadCenter}>
              <CircularProgress color="secondary" />
            </Box>
          }
        >
          <ProtectedRoute Component={LazyStoreCreation} />
        </Suspense>
      </AuthProvider>
    ),
    children: [
      {
        path: "",
        element: <Storeflow.RegisterStore />,
      },
    ],
  },
  {
    path: "/platform_auth/:product_id",
    element: (
      <Suspense
        fallback={
          <Box sx={loadCenter}>
            <CircularProgress color="secondary" />
          </Box>
        }
      >
        <LazyUserForm />
      </Suspense>
    ),
  },
  {
    path: "/whatsapp-checkout",
    element: (
      <Suspense
        fallback={
          <Box sx={loadCenter}>
            <CircularProgress color="secondary" />
          </Box>
        }
      >
        <LazyWhatsappCheckout />
      </Suspense>
    ),
  },
  {
    path: "/live/:roomName/:user_id",
    element: (
      <AuthProvider userType={"businessUser"}>
        <SocketProvider>
          <Suspense
            fallback={
              <Box sx={loadCenter}>
                <CircularProgress color="secondary" />
              </Box>
            }
          >
            <LazyLiveStreams />
          </Suspense>
        </SocketProvider>
      </AuthProvider>
    ),
  },
  {
    path: "/stores",
    element: (
      <StoresProvider>
      <Suspense
        fallback={
          <Box sx={loadCenter}>
            <CircularProgress color="secondary" />
          </Box>
        }
      >
        <LazyStores />
      </Suspense>
      </StoresProvider>
    ),
  },
  {
    path: "/streams",
    element: (
      <AuthProvider>      
      <StoresProvider>
      <Suspense
        fallback={
          <Box sx={loadCenter}>
            <CircularProgress color="secondary" />
          </Box>
        }
      >
        <LazyExploreStreams/>
      </Suspense>
      </StoresProvider>
      </AuthProvider>

    ),
  },
]);

export const shopRouter = createBrowserRouter([
  {
    path: "/",
    element: (
      <ShopProvider>
        <SearchProvider>
          <Suspense
            fallback={
              <Box sx={loadCenter}>
                <CircularProgress color="secondary" />
              </Box>
            }
          >
            <LazyShop />
          </Suspense>
        </SearchProvider>
      </ShopProvider>
    ),
    children: [
      {
        path: "",
        element: <Shop.MainComp />,
      },
      {
        path: "product/:id",
        element: <Shop.Product />,
      },
      {
        path: "/cart/checkout",
        element: <Shop.Checkout />,
      },
      {
        path: "cart",
        element: <Shop.Cart />,
      },
    ],
  },
  {
    path: "/shop",
    element: <></>,
  },
]);
