import { Box, Button, Container, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getNewArrivalProduct } from "../../api/ProductApis";

const NewArrivalSection = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const [newArrival, setNewArrival] = React.useState([]);
  const [loading, setLoading] = useState(false);

  let query = ""; // Initialize query variable
  if (window.location.hostname && window.location.pathname === "/") {
    query = window.location.hostname.split(".")[0]; // Set query variable for second URL
  } else if (search) {
    query = new URLSearchParams(search).get("q"); // Set query variable for first URL
  }

  const fetchNewArrivalProduct = async () => {
    setLoading(true);

    const response = await getNewArrivalProduct(query);
    if (response?.status === 200) {
      let data = response.data.newArrivalProducts;
      setNewArrival([...data]);
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchNewArrivalProduct();
  }, []);

  return (
    <Container maxWidth="lg">
      {newArrival?.length > 0 && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            py: "30px",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              fontWeight: "bold",
              color: "#707070",
              mb: "20px",
            }}
          >
            New Arrivals
          </Typography>
          {/* <Typography sx={{ color: "#707070", fontSize: "12px", mb: "40px" }}>
            There are many variations orem ipsum more
          </Typography> */}

          <Box
            sx={{
              display: "flex",
              gap: 3,
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            {newArrival?.map((arrivalProd) => (
              <Box
                sx={{
                  // border: "1px solid #00000059",
                  width: "200px",
                  height: "200px",
                  background: "#E1E1E1",
                  backgroundImage: `url(${arrivalProd?.images[0]})`,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  borderRadius: "10px",
                  backgroundPosition: "center",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "end",
                  pb: "30px",
                }}
              >
                {/* <Typography
                  variant="h5"
                  sx={{
                    color: "#264D59",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  {"10"}% OFF
                </Typography> */}
                <Button
                  sx={{
                    color: "#FC6B00",
                    textAlign: "center",
                    fontSize: "14px",
                    // background: "#40626D",
                    // color: "#fff",
                  }}
                  onClick={() => navigate(`product/${arrivalProd._id}`)}
                >
                  Shop Now
                </Button>
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </Container>
  );
};

export default NewArrivalSection;
