import { toast } from "react-toastify";
import axios from "./axios";

export const updateProfile = async (data, token) => {
  try {
    if (token) {
      return await axios.put(`/businessUser/updateProfile`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } else {
      return await axios.put(`/businessUser/updateProfile`, data);
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      // toast.error(error?.response?.data?.message);
    } else {
      toast.error("Error: Something went wrong. ");
    }
  }
};
