import React, { createContext, useContext, useEffect } from 'react';
import io from 'socket.io-client';

const SocketContext = createContext();

export const useSocket = () => {
  return useContext(SocketContext);
};

export function SocketProvider({ children }) {
  
  const socket = io(process.env.REACT_APP_WS_MAIN_URL);

  useEffect(() => {
    console.log("socket")
    return () => {
      socket.disconnect();
    };
  }, []);
  return (
    <SocketContext.Provider value={socket}>
      {children}
    </SocketContext.Provider>
  );
}
