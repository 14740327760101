import { toast } from "react-toastify";
import axios from "./axios";

export const createStore = async (data) => {
  try {
    return await axios.post(`/store/create`, data);
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error?.response?.data?.message);
    } else {
      toast.error("Error: Something went wrong. ");
    }
  }
};

export const verifyDomain = async (data) => {
  try {
    return await axios.post(`/store/verifyDomain`, {
      store_name: data,
    });
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error?.response?.data?.message);
    } else {
      toast.error("Error: Something went wrong. ");
    }
  }
};

export const updateStore = async (data) => {
  try {
    return await axios.put(`/store/updateStore`, data);
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error?.response?.data?.message);
    } else {
      toast.error("Error: Something went wrong. ");
    }
  }
};

export const getShopDetailsForCustomerView = async (query) => {
  try {
    return await axios.get(`/store/detail?business_name=${query}`);
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error?.response?.data?.message);
    } else {
      toast.error("Error: Something went wrong. ");
    }
  }
};

export const getShopViews = async (data) => {
  try {
    return await axios.get(`/store/getStoreViews`);
  } catch (error) {
    // if (error?.response?.data?.message) {
    //   toast.error(error?.response?.data?.message);
    // } else {
    //   toast.error("Error: Something went wrong. ");
    // }
  }
};

export const getShopAnalytics = async () => {
  try {
    return await axios.get(`/store/getStoreAnalytics`);
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error?.response?.data?.message);
    } else {
      toast.error("Error: Something went wrong. ");
    }
  }
}

export const getShopViewForCount = async (shop_name) => {
  try {
    return await axios.get(`/store/addStoreViews?shop_name=${shop_name}`);
  } catch (error) {
    console.log(error)
  }
}

export const getRandomStores = async () => {
  try {
    return await axios.get(`/store/getRandomStores`);
  } catch (error) {
    console.log(error)
  }
}
// get all stores
export const getAllStores = async (page) => {
  try {
    return await axios.get(`/store/getAllStores?page=${page}`);
  } catch (error) {
    console.log(error)
  }
}

// search stores
export const searchStores = async (storeName) => {
  try {
    return await axios.get(
      `/store/searchStores?q=${storeName}`
    );
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error?.response?.data?.message);
    } else {
      toast.error("Error: Something went wrong. ");
    }
  }
};
// search products by name 
// search suggestion 
export const searchProductSuggestion = async (keyword,shopName) => {
  try {
    console.log("storeName value test before call api of seach suggestion product ",keyword,shopName);
    return await axios.get(
      `/store/searchSuggestions/product/${keyword}/${shopName}`
    );
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error?.response?.data?.message);
    } else {
      toast.error("Error: Something went wrong. ");
    }
  }
};
// search suggestion 
export const searchSuggestion = async (keyword) => {
  try {
    console.log("storeName value test before call api of seach suggestion ",keyword);
    return await axios.get(
      `/store/searchSuggestions/${keyword}`
    );
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error?.response?.data?.message);
    } else {
      toast.error("Error: Something went wrong. ");
    }
  }
};

// Store filter
export const FilterStores = async (category) => {
  try {
    console.log("category value test",category);
    return await axios.get(
      `/store/searchStores?category=${category}`
    );
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error?.response?.data?.message);
    } else {
      toast.error("Error: Something went wrong. ");
    }
  }
};