import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Divider,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,

} from "@mui/material";
import { useTheme, useMediaQuery } from '@mui/material';
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { ShopContext } from "../../../context/ShopContext";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import Checkbox from "@mui/material/Checkbox";

const Index = () => {
  //-----useContext-------
  const {
    cartItems,
    updateQuantity,
    removeItem,
    selectItem,
    unSelectAllItems,
    selectAllItems,
    allItemsSelected,
    store,
    shopDetails,
    removeAll,
    checkoutItems,
    removeAnItem
  } = useContext(ShopContext);

  const navigate = useNavigate();

  const getTotalPrice = () => {
    let price = 0;

    cartItems.forEach((item) => {
      if(item.discount_price!==0){
        price += Number(item.quantity) * Number(item.discount_price);
      }else{
        price += Number(item.quantity) * Number(item.price);
      }
    });
    return price;
  };

  const handleQtyInc = (row) => {
    updateQuantity(row, ++row.quantity);
  };

  const handleQtyDec = (row) => {
    updateQuantity(row, --row.quantity);
  };

  const handleRemoveItem = (row) => {
    removeItem(row);
  };

  const handleSelectItem = (row, value) => {
    selectItem(row, value);
  };

  function handleGoBack() {
    navigate("/");
  }

  const breadcrumbs = [
    <Link
      // underline="hover"
      // to={`/shop?q=${endpoint?.split("/")[2]}`}
      key="1"
      // color="inherit"
      onClick={handleGoBack}
      style={{ textDecoration: "none" }}
    >
      <Typography
        sx={{
          textDecoration: "none",
          color: "#E6A31E",
          ":hover": { cursor: "pointer" },
        }}
      >
        {shopDetails?.store_name}
      </Typography>
    </Link>,
    <Typography key="3" sx={{ color: "#E6A31E", fontWeight: "500" }}>
      Cart
    </Typography>,
  ];
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.up('sm'));


  return (
    <Container
      maxWidth="lg"
      sx={{
        minHeight: "80vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          mt: "20px",
          gap: 1,
        }}
      >
        <Breadcrumbs
          separator={
            <NavigateNextIcon fontSize="small" sx={{ color: "#E6A31E" }} />
          }
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Box>

      <Divider />

      <Box
        sx={{
          py: "10px",
          display: "flex",
          gap: 3,
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Box sx={{ width: { xs: "100%", md: "65%" } }}>
          <TableContainer
            sx={{
              width: "100%",
              background: "#fff",
              borderRadius: "10px",
              mb: 3,
            }}
          >
            <Table aria-label="simple table">
              <TableBody>
                <TableRow
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell
                    align="left"
                    sx={{
                      width: "20px",
                    }}
                  >
                    <Checkbox
                      checked={allItemsSelected()}
                      sx={{ p: "0" }}
                      onChange={() => {
                        if (allItemsSelected()) {
                          unSelectAllItems();
                        } else {
                          selectAllItems();
                        }
                      }}
                      size="small"
                    />
                  </TableCell>

                  <TableCell sx={{ display: "flex", alignItems: "center" }}>
                    <Typography sx={{ fontWeight: "400" }}>
                      Select All ({checkoutItems.length || 0} Items)
                    </Typography>
                  </TableCell>

                  <TableCell
                    align="right"
                    sx={{
                      width: "20px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        sx={{ minWidth: "0", p: "0" }}
                        onClick={
                          
                          removeAll
                        }
                      >
                        <DeleteOutlineRoundedIcon sx={{ color: "#707070" }} />
                      </Button>
                    </Box>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <TableContainer
            sx={{ width: "100%", background: "#fff", borderRadius: "10px" }}
          >
            <Table aria-label="simple table">
              {/* <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}>Product</TableCell>
                  <TableCell align="right" sx={{ fontWeight: "bold" }}>
                    Quantity
                  </TableCell>
                  <TableCell align="right" sx={{ fontWeight: "bold" }}>
                    Total Price
                  </TableCell>
                </TableRow>
              </TableHead> */}
              {/* for md and above screen */}
             {isSmallScreen &&(
              <TableBody
            
              >
                {cartItems.map((row) => (
                  <TableRow
                    key={row._id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                  
                    <TableCell
                      align="left"
                      sx={{
                        width: "20px",
                      }}
                    >
                      <Checkbox
                        checked={row?.checked || false}
                        sx={{ p: "0" }}
                        onChange={(e) =>
                          handleSelectItem(row, e.target.checked)
                        }
                        size="small"
                      />
                    </TableCell>
                    <TableCell sx={{ display: "flex", alignItems: "center",gap:1 }}>
                      <img
                        src={row?.thumbnail_image}
                        alt="test"
                        style={{
                          width: "50px",
                          height: "50px",
                          // marginRight: "20px",
                          objectFit: "contain",
                        }}
                      />
                      <Tooltip title={row?.product_name} placement="top">
                      <Typography sx={{ fontWeight: "500", }}>
                        {
                          row?.product_name.length>20?(
                            row?.product_name.slice(0,20)+"..."
                          ):(
                            row?.product_name
                          )
                        }
                      </Typography>
                   </Tooltip>
                     
                    </TableCell>
                    <TableCell align="center">
                      <Box

                        sx={{ display: "flex", alignItems: "center" }}
                        >
                             
                       
                          <Typography sx={{fontSize:"12px", fontWeight: "500",textTransform:"uppercase",color:"#75A3E0" }}>
                          {row?.variant || "default"}
                        </Typography>
                       
                        </Box>
                          </TableCell>

                  
                    <TableCell align="right">
                      <Box
                        sx={{
                          borderRadius: "20px",
                          border: "1px solid #D8D8D8CC",
                          width: "80px",
                          mx: "auto",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Button
                          sx={{ minWidth: "0", p: 0, m: "6px" }}
                          onClick={() => handleQtyDec(row)}
                        >
                          <RemoveIcon />
                        </Button>
                        {row.quantity}
                        <Button
                          sx={{ minWidth: "0", p: 0, m: "6px" }}
                          onClick={() => handleQtyInc(row)}
                          disabled={row?.total_stock <= row.quantity}
                        >
                          <AddIcon />
                        </Button>
                      </Box>
                    </TableCell>
                    <TableCell align="right">
                      {
                        row.discount_price!==0?(
                          <Typography sx={{ fontWeight: "500" }}>
                          Rs&nbsp;{Number(row.quantity) * Number(row.discount_price)}
                        </Typography>
                        ):(
                          <Typography sx={{ fontWeight: "500" }}>
                        Rs&nbsp;{Number(row.quantity) * Number(row.price)}
                      </Typography>
                        )
                      }
                      
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        width: "20px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Button
                          sx={{ minWidth: "0", p: "0" }}
                          onClick={() => handleRemoveItem(row)}
                        >
                          <DeleteOutlineRoundedIcon sx={{ color: "#707070" }} />
                        </Button>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              )}
             
              {/* for sm and xs screen */}
              <Box
              sx={{
                display:{xs:"flex",sm:"none"},
                width:"100%"
              }}
              >
              <Box
              sx={{
                width:"100%",
                
              }}
              >
                {cartItems.map((row) => (
                 <Box
                 sx={{
                  display: "flex",
                  alignItems: "center",
                  pl: "15px",
                  py: "15px",
                  width: "100%",
                  
                 }}
                 >
                    <Box sx={{
                      width:"20px"
                    }}>
                   <Checkbox
                        checked={row?.checked || false}
                        sx={{ p: "0" }}
                        onChange={(e) =>
                          handleSelectItem(row, e.target.checked)
                        }
                        size="small"
                      />
                      </Box>
                      <Box sx={{
                        display: "flex",
                        marginLeft:"6px",
                        width:"100%",
                      }}>
                      <img
                        src={row.thumbnail_image}
                        alt="test"
                        style={{
                          width: "100px",
                          height: "auto",
                          objectFit: "contain",
                        }}
                      />
                      <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        ml: "5px",
                        width: "100%",
                      }}
                      >
                      <Typography sx={{ fontWeight: "500",fontSize:"13px" }}>
                        {row.product_name}
                      </Typography>
                      <Typography sx={{ fontWeight: "500",fontSize:"11px",color:"#FE9B0E" }}>
                       Only  {row.total_stock} item(s) in stock
                      </Typography>
                      <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                        alignItems: "center",

                      }}
                      >
                      <Typography sx={{ fontWeight: "500" ,color:"#FE9B0E"}}>
                      {
                        row.discount_price!=0?(
                          <Typography sx={{ fontWeight: "500" }}>
                          Rs&nbsp;{Number(row.quantity) * Number(row.discount_price)}
                        </Typography>
                        ):(
                          <Typography sx={{ fontWeight: "500" }}>
                        Rs&nbsp;{Number(row.quantity) * Number(row.price)}
                      </Typography>
                        )
                      }
                      </Typography>
                      <Box
                        sx={{
                          borderRadius: "20px",
                          // border: "1px solid #D8D8D8CC",
                          width: "80px",
                          height: "30px",
                          mx: "auto",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Button
                          sx={{ minWidth: "0", p: 0, m: "6px" }}
                          onClick={() => handleQtyDec(row)}
                        >
                          <RemoveIcon />
                        </Button>
                        <Typography>
                        {row.quantity}
                        </Typography>
                        <Button
                          sx={{ minWidth: "0", p: 0, m: "6px" }}
                          onClick={() => handleQtyInc(row)}
                          disabled={row?.total_stock <= row.quantity}
                        >
                          <AddIcon />
                        </Button>
                      </Box>
                      </Box>
                      </Box>
                      </Box>
                      

                 </Box>
                ))}
              </Box>
              </Box>
            </Table>
          </TableContainer>
        </Box>

        <Box
          sx={{
            width: { xs: "calc(100% - 40px)", md: "35%" },
            borderRadius: "10px",
            background: "#fff",
            display: "flex",
            flexDirection: "column",
            p: "20px",
            height: "fit-content",
          }}
        >
          <Container
            maxWidth={false}
            sx={{
              display: "flex",
              flexDirection: "column",
              maxWidth: "350px",
              px: "0px !important",
            }}
          >
            <Typography
              sx={{
                fontSize: "16px",
                color: "#000000B7",
                fontWeight: "500",
              }}
            >
              Order Summary
            </Typography>

            <Box sx={{ mt: 3 }}>
              {/* <Box sx={{ display: "flex", position: "relative", mb: "10px" }}>
                <TextField
                  label="Promo Code"
                  id="outlined-size-small"
                  size="small"
                  sx={{
                    borderTopRightRadius: "0px",
                    borderBottomRightRadius: "0px",
                    width: "100%",
                  }}
                />
                <Button
                  sx={{
                    color: "#fff",
                    position: "absolute",
                    background: "black",
                    ":hover": { background: "black" },
                    borderTopLeftRadius: "0px",
                    borderBottomLeftRadius: "0px",
                    right: "0",
                    height: "100%",
                  }}
                >
                  Apply
                </Button>
              </Box> */}

              <Typography
                sx={{
                  display: "flex",
                  fontSize: "12px",
                  color: "black",
                  justifyContent: "space-between",
                  mb: "10px",
                }}
              >
                SubTotal:&nbsp;{" "}
                <Typography
                  sx={{ fontSize: "12px", fontWeight: "bold", color: "black" }}
                >
                  {/* Rs {subtotal} */}
                  Rs {getTotalPrice()}
                </Typography>
              </Typography>

              {/* <Typography
                sx={{
                  display: "flex",
                  fontSize: "12px",
                  color: "#707070B7",
                  justifyContent: "space-between",
                  mb: "10px",
                }}
              >
                Discount:&nbsp;{" "}
                <Typography
                  sx={{ fontSize: "12px", fontWeight: "bold", color: "black" }}
                >
                  0
                </Typography>
              </Typography> */}

              {/* {getTotalPrice() <
                shopDetails?.delivery?.free_delivery_amount &&( */}
              <Typography
  sx={{
    display: "flex",
    fontSize: "12px",
    color: "black",
    justifyContent: "space-between",
    mb: "10px",
  }}
>
  Shipping:&nbsp;
  {
    (store?.delivery?.delivery_charges > 0 && getTotalPrice() < shopDetails?.delivery?.free_delivery_amount) ? (
      <Typography
        sx={{
          fontSize: "12px",
          fontWeight: "bold",
          color: "black",
        }}
      >
        Rs {store?.delivery?.delivery_charges || 0}
      </Typography>
    ) : (
      <Typography
        sx={{
          fontSize: "12px",
          fontWeight: "bold",
          color: "black",
        }}
      >
        Free
      </Typography>
    )
  }
</Typography>

              {/* )} */}

              <Typography
                sx={{
                  display: "flex",
                  fontSize: "12px",
                  color: "#707070B7",
                  justifyContent: "space-between",
                  mb: "10px",
                  fontWeight: "bold",
                }}
              >
                Total:&nbsp;{" "}
                <Typography
                  sx={{ fontSize: "12px", color: "black", fontWeight: "bold" }}
                >
                  Rs{" "}
                  {(getTotalPrice() <
                  shopDetails?.delivery?.free_delivery_amount
                    ? getTotalPrice() + store?.delivery?.delivery_charges
                    : getTotalPrice()) || 0}
                </Typography>
              </Typography>
            </Box>

            <Button
              sx={{
                background: "#FE9B0E",
                ":hover": { background: "#FE9B0E" },
                color: "#fff",
                borderRadius: "20px",
                mb: "10px",
              }}
              onClick={() => navigate(`/cart/checkout`)}
              disabled={checkoutItems?.length === 0 ? true : false}
            >
              Proceed to Checkout
            </Button>
            <Button
              sx={{
                background: "transparent",
                color: "#FE9B0E",
                border: "1px solid #FE9B0E",
                borderRadius: "20px",
                mb: "10px",
              }}
              onClick={() => navigate(`/`)}
            >
              Continue Shopping
            </Button>
          </Container>
        </Box>
      </Box>
    </Container>
  );
};

export default Index;
