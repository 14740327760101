import {
  Box,
  Button,
  CircularProgress,
  InputBase,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import PasswordResetSvg from "../../assets/Svg/PasswordResetSvg";
import MailIcon from "@mui/icons-material/Mail";
import { resetBusinessUserPassword, verifyResetOTPAPI } from "../../api/AuthApis";
import { toast } from "react-toastify";

const Reset = () => {
  const navigate = useNavigate();
  const [send, setSend] = useState({
    email: "",
    isSend: false,
    loading: false,
  });
  const [regError, setErr] = useState("")
  const [resetOTP, setResetOTP] = useState(false)
  const [otp, setOtp] = useState(['', '', '', '', '', '']);
  const [expiryTime, setExpiryTime] = useState(300);
  const [otpError, setOtpError] = useState(false);
  const refs = useRef([]);

  const handleEmailReset = async () => {
    let requestData = {};
    // check send.email is email or phone number
    if (send.email.includes('@')) {
      if (!send.email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(send.email)) {
        setErr("Invalid email entered!!!");
        return;
      }
      requestData = { email: send.email };
      sessionStorage.setItem("email", send.email);
    } else {
      const phoneRegex = /^\+\d{12}$/;
      if (!send.email || !phoneRegex.test(send.email)) {
        setErr("Invalid phone number entered!!!");
        return;
      }
      requestData = { phone_number: send.email };
      sessionStorage.setItem("phone_number", send.email);
    }


    setSend({ ...send, loading: true });

    const resp = await resetBusinessUserPassword(requestData);

    if (resp?.status === 200) {
      setSend({ ...send, isSend: true, loading: false });
      sessionStorage.setItem("resetOTP", "true");
      setResetOTP(true);
      setOtpError(false);
      setExpiryTime(300);
    }
    if (resp?.status === 400) {
      console.log("in cond:", resp?.data?.message)
      setErr(resp?.data?.message)
      setSend({ ...send, loading: false });
    }

    if (resp?.response?.status === 400) {
      setErr(resp?.response?.data?.message)
      setSend({ ...send, loading: false });
    }
    else {
      setSend({ ...send, loading: false });
    }
  };

  const handleOpenGmail = async () => {
    window.open("https://mail.google.com/", "_blank");
  };

  if (refs.current.length !== 6) {
    refs.current = Array.from({ length: 6 }, () => React.createRef());
  }

  const handleOTPChange = (index, value) => {
    // Ensure only numbers are entered
    if (/^\d*$/.test(value) && value.length <= 1) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Check if all OTP fields are filled
      if (newOtp.every((digit) => digit !== '')) {
        verifyOTP(newOtp.join(''));
      }

      // Focus on the next input field if not the last one
      if (index < refs.current.length - 1 && value !== '') {
        refs.current[index + 1].current.focus();
      }
    }
  };

  const verifyOTP = async (otp) => {
    // Your verification logic here
    console.log('Verifying OTP:', otp);

    const data = {
      otp: otp,
      email: sessionStorage.getItem("email"),
      phone_number: sessionStorage.getItem("phone_number")
    };

    const resp = await verifyResetOTPAPI(data);

    if (resp?.status === 200) {
      sessionStorage.removeItem("resetOTP");
      sessionStorage.removeItem("email");
      sessionStorage.removeItem("phone_number");
      setResetOTP(false);
      setOtpError(false);
      setOtp(['', '', '', '', '', '']);
      setExpiryTime(300);
      
      window.location.href = `/auth/reset/${resp?.data?.token}`
    }

    if (resp?.response?.status === 400) {
      setOtpError(true);
      toast.error(resp?.response?.data?.message);
      setOtp(['', '', '', '', '', '']);
      setExpiryTime(300);
    }
    
  };

  const resendHandler = async () => {
    const requestData = {
      email: sessionStorage.getItem("email"),
      phone_number: sessionStorage.getItem("phone_number")
    };

    const resp = await resetBusinessUserPassword(requestData);

    if (resp?.status === 200) {
      setExpiryTime(300);
      setOtpError(false);
      setOtp(['', '', '', '', '', '']);
      toast.success("OTP sent successfully");
    }

    if (resp?.response?.status === 400) {
      toast.error(resp?.response?.data?.message);
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setExpiryTime((prevTime) => {
        if (prevTime > 0 && !otpError && resetOTP) {
          return prevTime - 1;
        } else {
          setOtpError(true);
          clearInterval(timer);
          return 0;
        }
      });
    }, 1000);
  
    return () => clearInterval(timer);
  }, [otpError, resetOTP]);

  const minutes = Math.floor(expiryTime / 60);
  const seconds = expiryTime % 60;

  useEffect(() => {
    if (sessionStorage.getItem("resetOTP") === "true") {
      setResetOTP(true);
    }
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: 3,
      }}
    >
      <Box
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <PasswordResetSvg />
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{ fontWeight: "600", fontSize: "20px", color: "#252525" }}
        >
          {send.isSend ? "CHECK YOUR EMAIL" : "FORGOT PASSWORD?"}
        </Typography>
        <Typography
          sx={{ fontSize: "12px", color: "#8E8E8E", textAlign: "center" }}
        >
          {send.isSend ? (
            <>
              We sent a password reset link to <br /> email@fmail.com
            </>
          ) : (
            "No worries, we will send you reset instructions"
          )}
        </Typography>
      </Box>

      {
        resetOTP ? (
          <Box>
          <Typography sx={{ fontSize: "12px", color: "#8E8E8E", my: 2 }}>
            Enter Your OTP
          </Typography>
          <Box sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            gap: 2,
            width: "100%",
            alignItems: "center"
          }}>
            {Array.from({ length: 6 }, (_, index) => (
              <TextField
                key={index}
                inputRef={refs.current[index]}
                value={otp[index]}
                onChange={(e) => handleOTPChange(index, e.target.value)}
                sx={
                  otpError && {
                    borderRadius: "10px",
                    border: "1px solid red",
                    pointerEvents: "none",
                    // disable focus
                    "&:focus": {
                      border: "1px solid red",
                    }
                  }
                }
                inputProps={{
                  maxLength: 1,
                  type: 'text',
                  pattern: '[0-9]*' // Only allow numbers
                }}
              />
            ))}
          </Box>
          <Box sx={{
            display: "flex",
            justifyContent:"space-between",
            alignItems: "center",
            mt: 2
          }}>
            <Typography sx={{ fontSize: '12px', color: '#8E8E8E', my: 2 }}>
              {`OTP Expires in ${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`}
            </Typography>
            <Button variant="contained" color="secondary" sx={{ borderRadius: "20px"}} onClick={resendHandler}>
              Resend OTP
            </Button>
          </Box>
        </Box>
      ) : (
          <>
            {!send.isSend && (
              <Box>
                <Box
                  sx={{
                    px: "8px",
                    py: "5px",
                    borderRadius: "5px",
                    border: "1px solid #B7B7B7",
                    lineHeight: "0",
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  <MailIcon sx={{ color: "#B7B7B7" }} />
                  <InputBase
                    type="text"
                    placeholder="Email or Whatsapp no."
                    sx={{ ".css-yz9k0d-MuiInputBase-input": { p: "0" } }}
                    value={send.email}
                    onChange={(e) => setSend({ ...send, email: e.target.value })}
                  />
                </Box>
                {regError && (
                  <Typography style={{ color: '#FF0000', fontWeight: '400', fontSize: "12px", margin: "4px", marginBottom: "0" }}>{regError}</Typography>
                )}
              </Box>
            )}

            {send.isSend ? (
              <Box>
                {/* <Button
            variant="contained"
            color="secondary"
            sx={{ borderRadius: "20px", width: "100%", mb: "10px" }}
            onClick={handleOpenGmail}
          >
            Open email
          </Button> */}
                <Box sx={{ display: "flex", gap: 1 }}>
                  <Typography sx={{ fontSize: "12px" }}>
                    Didn't receive the email?
                  </Typography>
                  <Button
                    sx={{ p: "0", m: "0", textTransform: "none" }}
                    onClick={() => setSend({ ...send, isSend: false })}
                  >
                    <Typography
                      sx={{ fontSize: "12px", color: "black", fontWeight: "500" }}
                    >
                      Click to resend
                    </Typography>
                  </Button>
                </Box>
              </Box>
            ) : (
              <Button
                variant="contained"
                color="secondary"
                sx={{ borderRadius: "20px" }}
                onClick={handleEmailReset}
                disabled={send.loading}
              >
                {send.loading ? (
                  <CircularProgress size={"25px"} sx={{ color: "#fff" }} />
                ) : (
                  "Send Verification"
                )}
              </Button>
            )}
          </>
        )
      }


      <Button
        variant="outlined"
        color="secondary"
        sx={{ borderRadius: "20px" }}
        onClick={() => {
          navigate("/auth/login")
          setResetOTP(false)
          setOtpError(false)
          setExpiryTime(300)
          sessionStorage.removeItem("email")
          sessionStorage.removeItem("phone_number")
          sessionStorage.removeItem("resetOTP")
        }}
      >
        Back
      </Button>
    </Box>
  );
};

export default Reset;
