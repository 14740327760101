export const customStyles = {
  table: {
    style: {
      backgroundColor: "transparent",
      width: "100%",
      height: "100%",
      // border: "1px solid #00000029",
      // borderRadius: "20px",
    },
  },
  rows: {
    style: {
      backgroundColor: "transparent",
      minHeight: "50px",
      color: "#707070",
      fontSize: "12px",
      //   borderBottom: ".5px solid",
    },
  },
  pagination: {
    backgroundColor: "transparent", // Replace with the desired background color
  },
  headRow: {
    style: {
      backgroundColor: "#f7f7f7",
      minHeight: "30px",
      color: "#707070",
      fontWeight: "bold",
      borderRadius: "0px",
    },
  },
  subHeader: {
    style: {
      backgroundColor: "transparent",
      minHeight: "52px",
      justifyContent: "left",
      display: "flex",
      borderBottom: "0px solid #00000029",
    },
  },
  header: {
    style: {
      borderRadius: "20px",
      padding: "0px",
    },
  },
};
